import React from 'react';
import {Link, Route, Switch} from "react-router-dom";
import caller from "../call/caller";
import history from "../history";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as moment from "moment";
import Modal from "@material-ui/core/Modal";
import {Auth} from "aws-amplify";
import authManager from "../AuthManager";

const styles = (theme) => ({
  container: {
    position: "fixed",
    // overflowY: "",
    // background: "black",
    width: "100%",
    height: "100%",
    backgroundImage: "url('/session_back.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
  },
  inner: {
    width: "99%",
    maxWidth: "600px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  outer: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  formItem: {
    marginBottom: "12px",
    width: "100%"
  }
})

const SessionPhone = ({classes}) => {
  const [user, setUser] = React.useState({
    phoneNumberMiddle: '',
    phoneNumberLast: '',
  })

  const checkSignIn = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log(user);
      if (!user) {
        history.push({
          pathname: "/"
        })
      } else {
        if(user.attributes.phone_number_verified) {
          history.push({
            pathname: "/"
          })
        }
      }
    } catch (e) {
      history.push({
        pathname: "/"
      })
    }
  }
  React.useEffect(() => {
    checkSignIn()
  }, [])

  const updateInform = async () => {
    const {phoneNumberMiddle, phoneNumberLast} = user;
    if (phoneNumberMiddle.length != 4 || phoneNumberLast.length != 4) {
      alert('휴대폰번호를 입력해주세요.');
      return false;
    }
    const phoneNumber = `+82010${phoneNumberMiddle}${phoneNumberLast}`;
    const loginUser = await Auth.currentAuthenticatedUser();
    const data = await Auth.updateUserAttributes(loginUser, {
      phone_number: phoneNumber,
    });
    return true;
  }
  return (
    <div className={classes.container}>
      <div className={classes.outer}>
        <div className={classes.inner}>
          <Container fixed>
            {/*<div style={{textAlign:'center'}}>핸드폰번호</div>*/}
            {/*<div>새로운 약관에 따라 휴대폰 인증을 진행합니다.</div>*/}
            {/*<div>개인정보는 최고의 보안시설인 AWS cognito에 의해 보호됩니다.*/}
            {/*  회원탈퇴시 즉각 모든 정보를 폐기처분합니다.</div>*/}
            <div>서비스 품질관리 목적으로 휴대폰 인증을 진행합니다.</div>
            <div>탈퇴시 폐기하며 언제든지 재가입이 가능합니다.</div>

            <div style={{
              display: 'flex',
              justifyContent: 'space-around',
              height: '60px',
              marginTop: '10px',
              marginBottom: '20px'
            }}>
              <TextField
                style={{margin: 5}}
                className={classes.formItem}
                id="outlined-error-helper-text"
                value={'010'}
                disabled={true}
                variant="outlined"
              />
              <TextField
                style={{margin: 5}}
                value={user.phoneNumberMiddle} onChange={(e) => {
                setUser({...user, phoneNumberMiddle: e.target.value})
              }}
                name="phone_number1" className={classes.formItem}
                id="outlined-error-helper-text"
                variant="outlined"
              />
              <TextField
                style={{margin: 5}}
                value={user.phoneNumberLast} onChange={(e) => {
                setUser({...user, phoneNumberLast: e.target.value})
              }}
                name="phone_number2" className={classes.formItem}
                id="outlined-error-helper-text"
                variant="outlined"
              />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-around', height: '60px'}}>
              <Button style={{backgroundColor: 'black', color: 'white'}} onClick={async () => {
                try {
                  if (await updateInform()) {
                    history.push({
                      pathname: "/session/confirm"
                    })
                  }
                } catch(e) {
                  console.log(e);
                  alert("휴대전화 번호를 설정할 수 없습니다. 잠시 후 다시 시도해주세요.")
                }
              }} className={classes.formItem}>확인</Button>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-around', height: '60px'}}>
              <Button style={{backgroundColor: '#ff8686', color: 'white'}} onClick={async () => {
                await Auth.signOut();
                authManager.clearData()
                history.push({
                  pathname: '/'
                })
              }} className={classes.formItem}>로그아웃</Button>
            </div>
            <div><Link onClick={() => {
              document.location.href = 'https://www.sweet.chat/terms'
            }}>개인정보취급방침 확인하기</Link></div>
          </Container>
        </div>
      </div>
    </div>
  )
};

export default withStyles(styles)(SessionPhone);

