import * as React from "react";
import Modal from "@material-ui/core/Modal";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import caller from "../call/caller";
import history from "../history";

const styles = (theme) => ({
  paper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "95%",
    maxWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  item: {
    marginTop:"4px",
    marginBottom:"4px",
    textAlign:"center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width:"100%",
    height:"50px"
  },
  select: {
    background:"blue"
  },
  bottom: {
    textAlign:"right",
    // position: "absolute",
    // right:"10px",
    // bottom:"10px"
  },
  formItem: {
    marginBottom: "12px",
    width: "100%"
  },
});

class PasswordChange extends React.Component {
  state = {
    curPw:'', newPw:'', confirmNewPw:''
  };

  async componentDidMount() {
    history.push();
    this.backListener = history.listen((location, action) => {
      if (action === "POP") {
        this.props.onClose()
      }
    });
  }

  componentWillUnmount() {
    history.goBack();
    this.backListener();
  }

  changePw = async () => {
    const {curPw, newPw, confirmNewPw} = this.state;

    if (!curPw || !newPw || !confirmNewPw) {
      alert("비밀번호를 입력해주세요.")
      return false;
    }

    if (newPw.length < 8) {
      alert("비밀번호를 8자 이상으로 설정해주세요.")
      return false;
    }

    if (newPw != confirmNewPw) {
      alert("설정할 새로운 비밀번호가 서로 다릅니다.")
      return false;
    }

    const {data} = await caller.post('users/changePw', {
      prePassword: curPw,
      password: newPw,
    });
    if (data.successYn) {
      await alert('비밀번호가 변경되었습니다.');
      return true;
    } else {
      if (data.statusCode === 'incorrectPw') {
        await alert('기존 비밀번호가 잘못되었습니다.');
      } else {
        alert(
          '알 수 없는 에러가 발생하였습니다. 잠시 후 다시 시도하여주세요.',
        );
      }
    }
    return false;
  }

  render() {
    const {classes} = this.props;

    const {curPw, newPw, confirmNewPw} = this.state;
    return (
      <div className={classes.paper}>
        <div>
          {/*<div>* 현재 비밀번호</div>*/}
          <TextField
            style={{marginTop:'10px'}}
            type="password"
            value={curPw} onChange={(e) => {this.setState({curPw: e.target.value})}}
            name="pw" className={classes.formItem}
            id="outlined-error-helper-text"
            label="* 현재 비밀번호를 입력해주세요."
            // helperText={this.state.error ? "아이디와 비밀번호가 잘못 되었습니다." : ""}
            variant="outlined"
          />
          {/*<div>* 변경할 비밀번호</div>*/}
          <TextField
            style={{marginTop:'10px'}}
            type="password"
            value={newPw} onChange={(e) => {this.setState({newPw: e.target.value})}}
            name="pw" className={classes.formItem}
            id="outlined-error-helper-text"
            label="* 변경할 비밀번호를 입력해주세요."
            // helperText={this.state.error ? "아이디와 비밀번호가 잘못 되었습니다." : ""}
            variant="outlined"
          />
          <TextField
            type="password"
            value={confirmNewPw} onChange={(e) => {this.setState({confirmNewPw: e.target.value})}}
            name="pw_confirm" className={classes.formItem}
            id="outlined-error-helper-text"
            label="* 비밀번호를 다시 한 번 입력해주세요."
            // helperText={this.state.error ? "아이디와 비밀번호가 잘못 되었습니다." : ""}
            variant="outlined"
          />
        </div>
        <div className={classes.bottom}>
          <Button
            onClick={this.props.onCancel}
            color={'secondary'}>취소</Button>
          <Button
            onClick={async () => {
              if (await this.changePw()) {
                this.props.onConfirm()
              }
            }}
            color={"primary"}>확인</Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PasswordChange);
