import React from 'react';
import caller from "../call/caller";
import {withStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Auth} from "aws-amplify";
import moment from "moment";
import history from "../history";
import ReactS3Uploader from "react-s3-uploader";
import serverUrl from "../call/config";
import sb from "../sendbird/sb";
import {uploadImage} from "../util/uploadImage";
import myData from "../datastore/Mydata";

const styles = (theme) => ({
  outer: {
    marginTop: '10px',
    width: "100%",
  },
  inner: {
    maxWidth: "800px",
    width: "95%",
    display: "table",
    margin: "0 auto",
  },
  container: {
    width: "100%",
    display: "inline-block",
    textAlign: "left"
  },
  profile: {
    backgroundColor: 'white',
    // border: "1px solid",
    marginBottom: '30px',
    // height: "120px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  profileImage: {
    height: "120px",
    width: "120px",
    margin: '20px',
  },
  profileContent: {
    textAlign: "left",
    display: 'flex',
    // alignItems:'center',
    justifyContent: 'center',
    flexDirection: 'column'
    // marginLeft: "130px",
    // marginTop: "70px",
    // transform: "translate(0, -50%)",
  },
  formItem: {
    marginBottom: "12px",
    width: "100%"
  },
});

class MoreManageProfile extends React.Component {
  state = {
    user: {nickName: '', profile:''},
    alertMessageOpen: false
  };
  onConfirm = null;
  alertMessage = null;

  constructor(prop) {
    super(prop);
  }

  async componentDidMount() {
    const me = await this.getMyData();
    const {user} = me;
    const {
      disposition,
      grade,
      isExposeGrade,
      level,
      nickName,
      old,
      profile,
      profileImage,
      profileThumbnailUrl,
      sex,
      userSn
    } = user;

    this.setState({
      user: user
    })
    console.log(me);
  }

  async getMyData() {
    return myData.getMyData()
    // const response = await caller.post("/users/readProfile");
    // if (response.data.successYn) {
    //   let result = response.data.result;
    //   return result;
    // }
    // console.log(response.data.statusCode);
    // return null;
  }

  confirmMessageOepn = async (message, onConfirm) => {
    this.onConfirm = onConfirm;
    this.alertMessage = message
    return new Promise(resolve => {
      this.setState({
        alertMessageOpen: true
      })
    })
  }
  alertMessageOpen = (message) => {
    this.onConfirm = null;
    this.alertMessage = message
    this.setState({
      alertMessageOpen: true
    })
  };

  alertMessageClose = () => {
    this.onConfirm = null;
    this.alertMessage = null;
    this.setState({
      alertMessageOpen: false
    })
  }

  olds = () => {
    const olds = [];
    for (let i = 0; i < 80; i++) {
      olds.push(i + 20);
    }
    return olds;
  }

  async uploadeImage(file) {
    const {user} = this.state;
    const url = await uploadImage(file, user.userSn, user.sex, 'profile', 'G');
    return url;
  }
  render() {
    const {classes} = this.props;
    const {user, id, point} = this.state;
    console.log(this.state);
    return (
      <React.Fragment>
        <div className={classes.outer}>
          <div className={classes.inner}>
            <div className={classes.container}>
              <div className={classes.profile}>
                <Avatar className={classes.profileImage}
                        src={user.profileThumbnailUrl}
                />
                <div className={`filebox`}>
                  <label
                    style={{color: 'blue'}}
                    variant="contained"
                    component="label">
                    프로필 사진 변경
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        const url = await this.uploadeImage(file)
                        console.log(url);
                        this.setState({
                          user: {
                            ...this.state.user,
                            profileImage: url,
                            profileThumbnailUrl: url,
                          }
                        })
                      }}
                    />
                  </label>
                </div>
              </div>
              <div>
                <div>* 닉네임</div>
                <TextField
                  value={user.nickName} onChange={(e) => {
                    this.setState({
                      user: {
                        ...user,
                        nickName: e.target.value
                      }
                    })
                  }}
                  name="nickname" className={classes.formItem}
                  id="outlined-error-helper-text"
                  // label="* 닉네임을 입력해주세요."
                  variant="outlined"
                />
                <br/>
                <br/>
                <div>* 성별</div>
                <div style={{display: 'flex', justifyContent: 'space-around', height: '60px'}}>
                  <Button style={{margin: 4, backgroundColor: user.sex == 'M' ? 'blue' : '#dde5fc', color: 'white'}}
                          onClick={() => {
                            if (user.sex != 'M') {
                              alert("성별은 변경할 수 없습니다.")
                            }
                          }} className={classes.formItem}>남자</Button>
                  <Button style={{margin: 4, color: 'white', backgroundColor: user.sex == 'F' ? 'red' : '#ffe4e4'}}
                          onClick={() => {
                            if (user.sex != 'F') {
                              alert("성별은 변경할 수 없습니다.")
                            }
                          }} className={classes.formItem}>여자</Button>
                </div>
                <br/>
                <br/>
                <div>소개글</div>
                <TextField
                  value={user.profile || ""} onChange={(e) => {
                  this.setState({
                    user: {
                      ...user,
                      profile: e.target.value
                    }
                  })
                }}
                  name="profile" className={classes.formItem}
                  id="outlined-error-helper-text"
                  variant="outlined"
                />
                <br/>
                <br/>
                <div>* 나이</div>
                <select style={{height: '60px'}} className={classes.formItem}
                        value={user.old} onChange={(e) => {
                  this.setState({
                    user: {
                      ...user,
                      old: e.target.value
                    }
                  })
                }}
                >
                  <option value="0">* 나이를 선택해주세요.</option>
                  {
                    this.olds().map(old => {
                      return (<option key={old} value={old}>{old}</option>)
                    })
                  }
                </select>
                <br/><br/>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-around', height: '60px'}}>
              <Button style={{backgroundColor: 'black', color: 'white'}} onClick={async () => {
                if (!user.nickName || !user.sex || !user.old) {
                  return alert("모든 항목을 입력해주세요")
                }

                const {data} = await caller.post('users/updateProfile', {
                  user: user,
                });
                if (data.successYn) {
                  await alert('성공적으로 수정 되었습니다.');
                  await myData.resetMyData();

                } else {
                  if (data.statusCode === 'duplicateNickname') {
                    alert('닉네임이 중복입니다.');
                  } else if (data.statusCode === 'nicknameTooLong') {
                    alert('닉네임이 너무 깁니다.');
                  } else {
                    alert(
                      '알 수 없는 에러가 발생하였습니다. 잠시 후 다시 시도하여주세요.',
                    );
                  }
                }
              }} className={classes.formItem}>저장</Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

;

export default withStyles(styles)(MoreManageProfile);
