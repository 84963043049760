import * as React from 'react';
// import * as caller from 'caller';
// import caller from './caller';
// import config from '../../../config';
import moment from 'moment';
import idMaker from './idMaker';
import {Storage} from 'aws-amplify';
import caller from "../call/caller";

const putStorage = async (file, userSn, sex, folder, type) => {
  const today = moment().format('YYYYMMDD');
  let ext = 'jpg'
  let fileType = 'image/jpg'
  if (file.name) {
    const fileName = file.name;
    ext = fileName.slice(fileName.lastIndexOf('.') + 1);
  }
  if (file.type) {
    fileType = file.type
  }
  const key = `${folder}/${sex}/${today}/${userSn}_${idMaker()}_${type}.${ext}`;

  let uploaded = await Storage.put(key, file, {
    level: 'public',
    ContentType: fileType,
  });
  return `https://sweetchats3.s3.ap-northeast-2.amazonaws.com/public/${uploaded.key}`;
};
const searchImage = async (folder, searchKey) => {
  const {data} = await caller.post('picture/searchImg', {
    originPath: searchKey,
    category: folder,
  });

  if (data.successYn) {
    const url = data.result.url;
    return url;
  }

  return null;
};

const addImage = async (folder, searchKey, imageUrl) => {
  const {data} = await caller.post('picture/addImg', {
    originPath: searchKey,
    imageUrl: imageUrl,
    category: folder,
  });

  if (data.successYn) {
    return true;
  }
  return false;
};

// const _uploadImage = async (photo, userSn, sex, folder, type) => {
//   //type = G, S, A (일반, 비밀, 인증)
//   let {uri, fileName} = photo;
//   const today = moment().format('YYYYMMDD');
//   const ext = fileName.slice(fileName.lastIndexOf('.') + 1);
//   const response = await caller.get('s3/sign', {
//     params: {
//       objectName: `${userSn}_${idMaker()}_${type}.${ext}`,
//       contentType: photo.type,
//       path: `uploads/${folder}/${sex}/${today}/`,
//     },
//     headers: {Origin: config.BASE_URL},
//   });
//
//   try {
//     let blob = await getBlob(uri);
//     let signedUrl = response.data.signedUrl;
//     await fetch(signedUrl, {
//       method: 'PUT',
//       body: blob,
//       headers: {
//         'Content-Type': type,
//         'x-amz-acl': 'public-read',
//       },
//     });
//     let image = signedUrl.split('?')[0];
//     return image;
//   } catch (e) {
//     console.log(e);
//     return null;
//   }
// };

export const uploadImage = async (file, userSn, sex, folder, type) => {
  //type = profile, message, feed
  // let {uri, origURL, fileName} = photo;
  const origURL = `WEB-UPLOAD/${file.name}/${file.size}/${file.lastModified}`;

  const searched = await searchImage(folder, origURL);
  if (searched) {
    return searched;
  } else {
    try {
      const uploaded = await putStorage(file, userSn, sex, folder, type);
      if (uploaded) {
        await addImage(folder, origURL, uploaded);
      }
      return uploaded;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
};

export const uploadBase64 = async (base64, userSn, sex, folder, type) => {
  try {
    var bufBase64 = base64.replace(/^data:image\/\w+;base64,/,"");
    var buf = new Buffer.from(bufBase64, 'base64');

    const uploaded = await putStorage(buf, userSn, sex, folder, type);
    if (uploaded) {
      const now = moment().format('YYYYMMDD HH:mm');
      await addImage(folder, `WEB/AUTH/${now}`, uploaded);
    }
    return uploaded;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
