import React from 'react';
import {Link} from "react-router-dom";
import caller from "../call/caller";
import {geolocated} from "react-geolocated";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import ScopeChanger from "../component/ScopeChanger";
import Modal from "@material-ui/core/Modal";
import SendMessageView from "../modal/SendMessage";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile, isChrome, isSafari, isIOS, isAndroid, isSamsungBrowser, isIE
} from "react-device-detect";
import LocationPermissionGuide from "../component/LocationPermissionGuide";
import {Fab} from "@material-ui/core";
import history from "../history";
import UserDetail from "../modal/UserDetail";

const styles = (theme) => ({
  outer: {
    // paddingTop: "0px",
    width: "100%",
  },
  inner: {
    maxWidth: "800px",
    display: "table",
    margin: "0 auto",
  },
  container: {
    maxWidth: "800px",
    width: "95%",
    display: "inline-block",
    textAlign: "left"
  },

  wrapper: {
    position: "relative",
    width: "100%",
    overflow: "hidden"
  },
  left: {
    width: "50px",
    float: "left",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)"
  },
  middle: {
    paddingLeft: "60px",
    overflow: "hidden"
  },
  last: {
    width: "30px",
    float: "right",
  },
  avatar: {
    height: "50px",
    width: "50px"
  }
});

class Feed extends React.Component {
  constructor(props) {
    super(props);

    this.scope = props.match.params.scope;
    this.state = {
      feeds: [],
      needLocationPermission: false,
      sendMessageOpen: false,
      sendMessageTargetUserSn: null,

      profileModalOpen: false,
      profileModalUserSn: null,
    };
    this.newFeeds = [];
  }

  sendMessageOpen = (userSn) => {
    this.setState({
      sendMessageOpen: true,
      sendMessageTargetUserSn: userSn
    })
  };
  sendMessageClose = () => {
    this.setState({
      sendMessageOpen: false,
      sendMessageTargetUserSn: null
    })
  };

  async componentDidMount() {
    window.addEventListener("focus", this.onFocus)
    window.scroll(0, 0);  // reset the scroll position to the top left of the document.
    await this.initFeed()
    this.getFeed()
  }


  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus)
  }

  onFocus() {
    console.log("feed focus")
  }

  async shouldComponentUpdate(nextProps, nextState, nextContext) {
    const newScope = nextProps.match.params.scope;

    if (newScope != this.scope) {
      this.scope = newScope;
      await this.initFeed();
      await this.getFeed();
    }

    const newCoords = nextProps.coords;
    const strLocation = caller.defaults.headers.common['Request-Location'];
    const location = strLocation && JSON.parse(strLocation)
    if (newCoords) {
      caller.defaults.headers.common['Request-Location'] = JSON.stringify({
        latitude: newCoords.latitude,
        longitude: newCoords.longitude
      });
    }

    if (location == null && newCoords != null) {
      await this.initFeed();
      await this.getFeed();
    }


    return true;
  }

  async initFeed() {
    return new Promise(resolve => {
      this.setState({
        feeds: []
      }, () => {
        resolve();
      });
    })
  }

  async getFeed() {
    let data = {
      count: 30,
      criteria: {
        scope: this.scope,
        category: [3]
      }
    };
    if (this.state.feeds.length > 0) {
      const lastOne = this.state.feeds[this.state.feeds.length - 1];
      const lastTalkSn = lastOne.talkSn
      data.lastFeedSn = lastTalkSn;
    }

    //TODO
    //현재 위치를 알 수 없음

    const strLocation = caller.defaults.headers.common['Request-Location'];
    console.log(`strLocation is ${strLocation}`);
    if (strLocation) {
      const location = JSON.parse(strLocation)
      if (location) {
        data.criteria.location = {
          latitude: location.latitude,
          longitude: location.longitude
        }
      }
    }

    let needLocationPermission = data.criteria.scope != "all" && data.criteria.location == null;
    if (needLocationPermission) {
      this.setState({
        needLocationPermission: true
      });
      return [];
    } else {
      this.setState({
        needLocationPermission: false
      });
    }

    const response = await caller.post("/feed/readList", data);
    if (response.data.successYn) {
      this.newFeeds = response.data.result;
      const prevScroll = window.pageYOffset;
      this.setState({
        feeds: this.state.feeds.concat(this.newFeeds)
      }, () => {
        window.scrollTo(0, prevScroll)
      })
    }

  }

  differTimeToString(differTime) {
    differTime = differTime.replace("s", "초 전")
    differTime = differTime.replace("m", "분 전")
    differTime = differTime.replace("H", "시간 전")
    differTime = differTime.replace("D", "일 전")
    differTime = differTime.replace("W", "주 전")
    differTime = differTime.replace("M", "개월 전")
    differTime = differTime.replace("Y", "년 전")
    return differTime;
  }

  getGender(sex) {
    switch (sex) {
      case "M":
        return "남자";
      case "F":
        return "여자";
      case "G":
        return "게이";
      case "L":
        return "레즈비언";
    }
    return "";
  }

  render() {
    const {classes} = this.props;

    if (!this.state.feeds) {
      this.state.feeds = [];
    }
    return (
      <React.Fragment>
        <ScopeChanger
          data={[
            {
              name: "전체",
              scope: "all"
            },
            {
              name: "주변",
              scope: "around"
            },
            {
              name: "근처",
              scope: "near"
            }
          ]}
          scope={this.scope} to={"/feed/:scope"}/>

        <div className={classes.outer}>
          <div className={classes.inner}>
            {this.state.needLocationPermission ? <LocationPermissionGuide/> : ""}
            {
              this.state.feeds.map((feed) => {
                return (
                  <div key={feed.talkSn} className={classes.container}>
                    <div style={{height: "20px"}}></div>
                    <div className={classes.wrapper}>
                      <div className={classes.left}>
                        <Avatar onClick={() => {
                          this.setState({profileModalOpen: true, profileModalUserSn: feed.userSn});
                        }} className={classes.avatar} alt={feed.nickName} src={feed.profileImage}/>
                      </div>
                      <div className={classes.last}>
                        {feed.userSn > 0 ? <Icon onClick={() => {
                          this.sendMessageOpen(feed.userSn)
                        }}> message </Icon> : ""}

                      </div>
                      <div className={classes.middle}>
                        <Typography
                          variant="subtitle1"
                          color={feed.sex == "F" || feed.sex == "L" ? "secondary" : "primary"}
                          className={classes.card}
                        >
                          [{this.getGender(feed.sex)}/{feed.old}살] {feed.nickName}
                        </Typography>
                        <div>
                          <Typography
                            display="inline"
                            variant="body1">
                            {feed.distance || "???"}km
                          </Typography>
                          &nbsp;&nbsp;
                          <Typography
                            display="inline"
                            color="textSecondary"
                            variant="body2">
                            {this.differTimeToString(feed.differTime)}
                          </Typography>

                        </div>
                      </div>

                    </div>
                    <div style={{height: "5px"}}></div>
                    <Typography
                      variant="subtitle1"
                      className={classes.card}>
                      {feed.content}
                    </Typography>
                    {feed.image && feed.image.length > 0 ? <img src={feed.image[0].imageUrl} style={{width: "100%"}}/> :
                      <div></div>}
                    <div style={{height: "20px"}}></div>
                    <Divider/>
                  </div>
                )
              })
            }
          </div>
        </div>
        {this.state.needLocationPermission ? "" :
          <button style={{width: "100%", maxWidth: "800px", height: "50px"}} onClick={() => {
            this.getFeed();
          }}>더보기</button>}
        <br/>
        <Typography
          variant="caption"
          color={"textSecondary"}>
          <a href={"https://www.sweet.chat/terms"}>서비스 약관</a> | <a href={"https://www.sweet.chat/terms"}>개인정보처리방침</a> | <a href={"mailto:random.chatting.69@gmail.com"}>고객센터</a> | <a href={"mailto:random.chatting.69@gmail.com"}>© 스윗커뮤니티</a>
        </Typography>
        <Modal
          open={this.state.sendMessageOpen}
          onClose={this.sendMessageClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div>
            {this.state.sendMessageTargetUserSn ?
              <SendMessageView
                targetUserSn={this.state.sendMessageTargetUserSn}
                onClose={() => {
                  this.sendMessageClose()
                }}/> : ""}
          </div>
        </Modal>
        <Fab
          onClick={() => {
            history.push({
              pathname: "/feed/write"
            })
          }}
          className={"d-none d-lg-block"}
          style={{position: 'fixed', bottom: '100px', right: '50%', marginRight: '-400px'}} color="primary"
          aria-label="add">
          <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Icon className={classes.itemIcon}> create</Icon>
          </div>
        </Fab>
        <Fab
          onClick={() => {
            history.push({
              pathname: "/feed/write"
            })
          }}
          className={`d-block d-lg-none `} style={{position: 'fixed', bottom: '100px', right: '30px'}}
          color="primary" aria-label="add">
          <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Icon className={classes.itemIcon}> create</Icon>
          </div>
        </Fab>

        <Modal
          open={this.state.profileModalOpen}
          onClose={() => {
            this.setState({profileModalOpen: false, profileModalUserSn: null})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <UserDetail
            onClose={() => {
              this.setState({profileModalOpen: false, profileModalUserSn: null})
            }}
            userSn={this.state.profileModalUserSn}/>
        </Modal>
      </React.Fragment>
    )
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(withStyles(styles)(Feed));
