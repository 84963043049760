import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import caller from "../call/caller";
import {geolocated} from "react-geolocated";
import Typography from "@material-ui/core/Typography";
import SendMessageView from "./SendMessage";
import Modal from "@material-ui/core/Modal";
import {Link} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import {Button} from "@material-ui/core";
import PictureView from "./PictureView";
import history from '../history';

const styles = (theme) => ({
  outer: {
    // paddingTop: "0px",
    // width: "100%",
    // height: '100%',
    // backgroundColor:'white'
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "95%",
    maxWidth: "700px",
    maxHeight: '95%',
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    overflow:'hidden',
    overflowY:'scroll'
    // padding: theme.spacing(2, 4, 3)
  },
  inner: {
    // width: "90%",
    // display: "table",
    // margin: "0 auto",
    margin:'10px',
    backgroundColor: 'white',
  },
  container: {
    maxWidth: "800px",
  },
  profile: {
    // marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
  },
  profileRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  profileRightTop: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    // paddingLeft: '15px',
    // paddingRight: '15px',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  profileRightBottom: {
    height: '50px',
  },
  profileLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileImage: {
    height: "80px",
    width: "80px",
    margin: '10px'
  },
  profileContent: {
    margin: '0px',
    marginLeft: '10px',
  },
  profileInformItem: {
    width: '70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  actionItem: {
    marginLeft: 'auto',
    marginTop: 'auto',
    height: '100%',
    marginRight: '20px',
    display: 'flex',
    width: '150px',
    // justifyContent:'flex-end',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  feedContainer: {
  },
  feed: {
    marginTop: '20px'
  },
  writerInform: {
    display: 'flex',
    flexDirection: 'row'
  },
  left: {
    marginLeft: '5px',
    width: "50px",
  },
  middle: {
    marginLeft: '15px'
  },
  avatar: {
    height: "50px",
    width: "50px"
  },
  card: {
    marginLeft: '5px',
    marginTop: '10px'
  },
  popup: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "95%",
    maxWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  closeButtonContainer: {
    display:'flex',
    justifyContent:'flex-end',
    margin:'20px',
  }
});

class UserDetail extends React.Component {
  state = {
    user: {},
    distance: "???km",
    talks: [],
    isFollowed: false,
    sendMessageOpen: false,
    sendMessageTargetUserSn: null,
    alertMessageOpen: false,

    pictureViewOpen: false,
    pictureUrl: null,
  };

  alertMessage = null;
  onConfirm = null;

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.userSn = this.props.userSn;
    this.fetchUserData();
    history.push();
    this.backListener = history.listen((location, action) => {
      console.log("user detail")
      if (action === "POP") {
        console.log(history.location);
        this.props.onClose()
      }
    });
  }

  componentWillUnmount() {
    history.goBack();
    this.backListener();
  }

  async fetchUserData() {
    if (!this.userSn) {
      return;
    }

    const response = await caller.post("/users/readProfile", {userSn: this.userSn})
    if (response.data.successYn) {
      this.setState({
        user: response.data.result.user,
        distance: response.data.result.distance,
        isFollowed: response.data.result.isFollowed,
        talks: response.data.result.talk,
      })
    }
  }

  async shouldComponentUpdate(nextProps, nextState, nextContext) {
    const newCoords = nextProps.coords;
    const coords = this.props.coords
    let fetch = false;

    if (this.userSn != nextProps.userSn) {
      this.userSn = nextProps.userSn;
      fetch = true;
    }

    if (coords != newCoords) {
      caller.defaults.headers.common['Request-Location'] = JSON.stringify({
        latitude: newCoords.latitude,
        longitude: newCoords.longitude
      });
      fetch = true;
    }
    if (fetch) {
      this.fetchUserData()
    }

    return true;
  }

  getGender(sex) {
    switch (sex) {
      case "M":
        return "남자";
      case "F":
        return "여자";
      case "G":
        return "게이";
      case "L":
        return "레즈비언";
    }
    return "";
  }

  sendMessageOpen = (userSn) => {
    this.setState({
      sendMessageOpen: true,
      sendMessageTargetUserSn: userSn
    })
  };
  sendMessageClose = () => {
    this.setState({
      sendMessageOpen: false,
      sendMessageTargetUserSn: null
    })
  };

  confirmMessageOepn = async (message, onConfirm) => {
    this.onConfirm = onConfirm;
    this.alertMessage = message
    return new Promise(resolve => {
      this.setState({
        alertMessageOpen: true
      })
    })
  }
  alertMessageOpen = (message) => {
    this.onConfirm = null;
    this.alertMessage = message
    this.setState({
      alertMessageOpen: true
    })
  };

  alertMessageClose = () => {
    this.onConfirm = null;
    this.alertMessage = null;
    this.setState({
      alertMessageOpen: false
    })
  }

  block = async () => {
    this.confirmMessageOepn("차단하시겠습니까? 더 이상 해당 사용자가 쪽지를 보낼 수 없습니다.", async () => {
      const userSn = this.userSn;
      if (!userSn) {
        return;
      }

      const response = await caller.post("users/blockChatUser", {targetUserSn: userSn})
      if (response.data.successYn) {
        this.alertMessageOpen("차단되었습니다.");
      } else {
        console.log(response.data.statusCode);
      }
    })
  }
  chat = async () => {
    const userSn = this.userSn;
    if (!userSn) {
      return;
    }

    this.sendMessageOpen(userSn);
  }
  thumbUp = async () => {
    const userSn = this.userSn;
    if (!userSn) {
      return;
    }

    const response = await caller.post("users/likeUser", {targetUserSn: userSn})
    if (response.data.successYn) {
      const {updatedGrade} = response.data.result
      this.updateGrade(updatedGrade);

      this.alertMessageOpen("좋아요를 눌렀습니다.");
    } else if (response.data.statusCode == "alreadyLike") {
      this.alertMessageOpen("이미 좋아요를 눌렀습니다.");
    }
  }
  follow = async () => {
    this.confirmMessageOepn("하트 5개가 소모됩니다. 계속 하시겠습니까?", async () => {
      const userSn = this.userSn;
      if (!userSn) {
        return;
      }

      const response = await caller.post("users/followUser", {targetUserSn: userSn})
      if (response.data.successYn) {
        const {updatedGrade} = response.data.result
        this.updateGrade(updatedGrade);
        this.alertMessageOpen("즐겨찾기에 추가되었습니다.");
      }
    })
  }
  unfollow = async () => {
    const userSn = this.userSn;
    if (!userSn) {
      return;
    }

    const response = await caller.post("users/unFollowUser", {targetUserSn: userSn})
    if (response.data.successYn) {
      this.alertMessageOpen("즐겨찾기가 취소되었습니다.");
      this.setState({
        isFollowed: false
      })
    }
  }

  updateGrade(updatedGrade) {
    const user = {
      ...this.state.user,
      grade: this.state.user.grade + updatedGrade
    };

    this.setState({
      isFollowed: true,
      user: user
    })
  }

  differTimeToString(differTime) {
    differTime = differTime.replace("s", "초 전")
    differTime = differTime.replace("m", "분 전")
    differTime = differTime.replace("H", "시간 전")
    differTime = differTime.replace("D", "일 전")
    differTime = differTime.replace("W", "주 전")
    differTime = differTime.replace("M", "개월 전")
    differTime = differTime.replace("Y", "년 전")
    return differTime;
  }

  pictureViewClose() {
    this.setState({
      pictureViewOpen: false,
      pictureUrl: null
    })
  }

  render() {
    const {classes} = this.props;

    if (!this.userSn) {
      return (
        <div className={classes.outer}>
          <div className={classes.inner}>
            <div className={classes.container}>
              <div style={{height:'300px'}}></div>
            </div>
          </div>
        </div>
      )
    }

    const grade = this.state.user.grade || "0";
    const nickName = this.state.user.nickName || "";
    const old = this.state.user.old || "";
    const profile = this.state.user.profile || "";
    const profileImage = this.state.user.profileImage || "";
    const profileThumbnailUrl = this.state.user.profileThumbnailUrl || "";
    const sex = this.state.user.sex || "";
    const userSn = this.state.user.userSn || "";
    const {distance, isFollowed, talks} = this.state;
    const feed = talks[0];

    return (
      <div className={classes.outer}>
        <div className={classes.inner}>
          <div className={classes.container}>
            <div className={classes.profile}>
              <div className={classes.profileLeft}>
                <Avatar
                onClick={() => {
                  this.setState({
                    pictureViewOpen: true,
                    pictureUrl: profileImage
                  })
                }}
                  className={classes.profileImage}
                        src={profileThumbnailUrl}
                />
                <div className={classes.profileContent}>
                  <Typography variant="subtitle2">
                    {nickName}
                  </Typography>
                  <Typography variant="subtitle2">
                    {profile}
                  </Typography>
                </div>
              </div>
              <div className={classes.profileRight}>
                <div className={classes.profileRightTop}>
                  <div className={classes.profileInformItem}>
                    <Icon fontSize={'default'} className={classes.itemIcon}> emoji_events</Icon>
                    <Typography variant="subtitle2">
                      {grade}
                    </Typography>
                  </div>
                  <div className={classes.profileInformItem}>
                    <Icon fontSize={'default'} className={classes.itemIcon}> place</Icon>
                    <Typography variant="subtitle2">
                      {distance || "???"}km
                    </Typography>
                  </div>
                  <div className={classes.profileInformItem}>
                    <Icon fontSize={'default'} className={classes.itemIcon}> emoji_people</Icon>
                    <Typography variant="subtitle2">
                      {old}세 / {this.getGender(sex)}
                    </Typography>
                  </div>
                </div>
                <div className={classes.profileRightBottom}>
                  <div className={classes.actionItem}>
                    <Icon
                      onClick={() => {this.block()}}
                      fontSize={'default'} className={classes.itemIcon}> block</Icon>
                    {
                      this.state.isFollowed ?
                        (
                          <Icon
                            onClick={() => {
                              this.unfollow()
                            }}
                            fontSize={'default'} className={classes.itemIcon}> star</Icon>
                        ) :
                        (
                          <Icon
                            onClick={() => {
                              this.follow()
                            }}
                            fontSize={'default'} className={classes.itemIcon}> star_border</Icon>
                        )
                    }

                    <Icon
                      onClick={() => {this.chat()}}
                      fontSize={'default'} className={classes.itemIcon}> mail</Icon>
                  </div>
                </div>
              </div>
            </div>
            <Typography style={{marginTop: '20px'}} variant="h6">최근 피드</Typography>

            <Divider/>
            <div className={classes.feedContainer}>
              {feed == null ?
                (
                  <div>작성한 피드가 없습니다.</div>
                )
                :
                (
                  <div key={feed.talkSn} className={classes.feed}>
                    <div className={classes.writerInform}>
                      <div className={classes.left}>
                        <Avatar className={classes.avatar} alt={feed.nickName} src={feed.profileThumbnailUrl}/>
                      </div>
                      <div className={classes.middle}>
                        <Typography
                          variant="subtitle1"
                          color={feed.sex == "F" || feed.sex == "L" ? "secondary" : "primary"}
                        >
                          [{this.getGender(feed.sex)}/{feed.old}살] {feed.nickName}
                        </Typography>
                        <div>
                          <Typography
                            display="inline"
                            color="textSecondary"
                            variant="body2">
                            {this.differTimeToString(feed.differTime)}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Typography
                      variant="subtitle1"
                      className={classes.card}>
                      {feed.content}
                    </Typography>
                    {feed.image && feed.image.length > 0 ?
                      <img src={feed.image[0].imageUrl} style={{width: "90%", overflow:'hidden'}}/> :
                      <div></div>
                    }
                    <div style={{height: "20px"}}></div>
                  </div>
                )
              }
            </div>
            <div className={classes.closeButtonContainer}>
              <Button style={{color:'#445bd9'}} onClick={() => {
                this.props.onClose()
              }}>닫기</Button>
            </div>
          </div>
        </div>

        <Modal
          open={this.state.sendMessageOpen}
          onClose={this.sendMessageClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div>
            {this.state.sendMessageTargetUserSn ?
              <SendMessageView
                targetUserSn={this.state.sendMessageTargetUserSn}
                onClose={() => {
                  this.sendMessageClose()
                }}/> : ""}
          </div>
        </Modal>

        <Modal
          open={this.state.alertMessageOpen}
          onClose={this.alertMessageClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div className={classes.popup}>
            {this.alertMessage}
            {this.onConfirm ? <div style={{textAlign: "right"}} onClick={() => {
              this.onConfirm()
            }}>확인</div> : <div></div>}
          </div>
        </Modal>

        <Modal
          open={this.state.pictureViewOpen}
          onClose={() => {
            this.pictureViewClose()
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div>
            <PictureView
              // id={/* TODO */}
              imageUrl={this.state.pictureUrl}
              onClose={() => {
                this.pictureViewClose()
              }}/>
          </div>
        </Modal>
      </div>
    )
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(withStyles(styles)(UserDetail));
