import React from 'react'
import {withStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import myData from "../datastore/Mydata";
import {Button, TextareaAutosize, TextField} from "@material-ui/core";
import {geolocated} from "react-geolocated";
import caller from "../call/caller";
import history from "../history";

const FeedWrite = ({classes, coords}) => {
  const [user, setUser] = React.useState({
    nickName: '',
    old: '',
    profile: ''
  })
  const [content, setContent] = React.useState('');
  const [location, setLocation] = React.useState(null);
  React.useEffect(() => {
    const me = myData.getMyData()
    const {user} = me;
    setUser(user);

    const strLocation = caller.defaults.headers.common['Request-Location'];
    const location = strLocation && JSON.parse(strLocation)
    setLocation(location);
  }, [])

  React.useEffect(() => {
    setLocation(coords);
  }, [coords])

  const write = async () => {
    const body = {
      content: content,
      category: 3,
      imageUrl: [],
      coords: location && {
        latitude: location.latitude,
        longitude: location.longitude,
      },
    };

    const {data} = await caller.post('feed/write', body);
    if (data.successYn) {
      await alert('정상적으로 등록이 되었습니다.');
      history.goBack();
    } else {
      if (data.statusCode == 'tooOftenFeed') {
        alert('도배성 피드가 감지되었습니다. 잠시 후 다시 시도해주세요.');
      }
      if (data.statusCode == 'lack point') {
        alert('하트가 부족합니다. 홍보글을 작성하기 위해서는 하트가 필요합니다.');
      }
      alert('알 수 없는 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.');
    }
  }
  return (
    <div className={classes.outer}>
      <div className={classes.inner}>
        <div className={classes.container}>
          <div className={classes.profile}>
            <Avatar className={classes.profileImage}
                    src={user.profileThumbnailUrl}
            />
            <div className={classes.profileContent}>
              <Typography variant="subtitle1">
                {user.nickName} ({user.old}세) <br/>
                {user.profile} <br/>
              </Typography>
            </div>
          </div>
          <div style={{backgroundColor:'#d5d3bc', padding:'20px'}}>
            <Typography variant="subtitle2">
              아래에 해당되는 글은 삭제 조치되고 이용에 제재가 가해질 수 있습니다.
            </Typography>
            <Typography variant="subtitle2">
              - 마약, 사기와 같은 불법적인 내용을 담은 글
            </Typography>
            <Typography variant="subtitle2">
              - 조건만남, 19금 영상 거래와 같은 음란성 글
            </Typography>
            <Typography variant="subtitle2">
              - 광고 및 홍보성 글
            </Typography>
          </div>
          <div style={{marginTop:'10px'}}>
            <TextareaAutosize
              value={content}
              onChange={(e) => {setContent(e.target.value)}}
              style={{width:'100%', height:'250px'}}></TextareaAutosize>
          </div>
          <div style={{textAlign:'right'}}>
            <Button variant="contained" onClick={() => {write()}}>작성하기</Button>
          </div>
        </div>
      </div>
    </div>
  )
};

const styles = (theme) => ({
  outer: {
    // paddingTop: "0px",
    width: "100%",
  },
  inner: {
    width: "100%",
    maxWidth: "800px",
    display: "table",
    margin: "0 auto",
  },
  container: {
    marginTop:'10px',
    maxWidth: "800px",
    width: "95%",
    display: "inline-block",
    textAlign: "left",
  },
  profile: {
    backgroundColor: 'white',
    // border: "1px solid",
    marginBottom: '10px',
    height: "90px",
    display: 'flex',
    alignItems: 'center'
  },
  profileImage: {
    // marginTop: "25px",
    // marginLeft: "25px",
    height: "60px",
    width: "60px",
    margin: '20px'
    // float: "left",
  },
  profileContent: {
    textAlign: "left",
    display: 'flex',
    // alignItems:'center',
    justifyContent: 'center',
    flexDirection: 'column'
    // marginLeft: "130px",
    // marginTop: "70px",
    // transform: "translate(0, -50%)",
  },
});

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(withStyles(styles)(FeedWrite));

