import React from 'react';
import {Redirect, Route, Router, Switch} from "react-router-dom";
import Feed from "./Feed";
import NearFriends from "./NearFriends";
import Chat from "./Chat";
import More from "./More";
import history from "../history.js";
import Navigation from "../Navigation";
import * as SendBird from "sendbird";
import Sb from "../sendbird/sb";
import caller from "../call/caller";
import ChatList from "./ChatList";
import UserDetail from "../modal/UserDetail";
import {geolocated} from "react-geolocated";
import Message from "./Message";
import authManager from "../AuthManager";
import {Auth as AWSAuth} from "@aws-amplify/auth/lib-esm/Auth";
import Typography from "@material-ui/core/Typography";
import MoreManageProfile from "./More_manage_profile";
import MoreManageAccount from "./More_manage_account";
import {Fab} from "@material-ui/core";
import FeedWrite from "./FeedWrite";
import Camera from "./Camera";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unreadCount: 0
    }
  }

  preventPinchScale (e) {
    e.preventDefault();
    document.body.style.zoom = 0.99;
  }

  async componentDidMount() {
    window.addEventListener("focus", this.onFocus)

    try {
      const cognitoUser = await AWSAuth.currentAuthenticatedUser()
      if (!cognitoUser) {
        authManager.clearData()
        history.push({
          pathname: "/session/signin"
        })
        return;
      }
      if(!cognitoUser.attributes.phone_number_verified) {
        history.push({
          pathname: "/session/phone"
        })
        return;
      }
    } catch(e) {
      if (e == 'not authenticated') {
        authManager.clearData()
        history.push({
          pathname: "/session/signin"
        })
      }
      console.log(e);
    }

    document.addEventListener('gesturestart', this.preventPinchScale);
    document.addEventListener('gesturechange', this.preventPinchScale);
    document.addEventListener('gestureend', this.preventPinchScale);

    Sb.setUnreadCountChangeListener("Layout", (count) => {
      this.setState({
        unreadCount: count
      })
    });
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus)

    document.removeEventListener('gesturestart', this.preventPinchScale);
    document.removeEventListener('gesturechange', this.preventPinchScale);
    document.removeEventListener('gestureend', this.preventPinchScale);
    Sb.removeUnreadCountChangeListener("Layout")
  }

  onFocus() {
    console.log('window focus')
    Sb.notify();
  }

  render() {
    function showBottomNavigation() {
      return !window.location.pathname.startsWith("/chat/") && !window.location.pathname.startsWith("/user/")
    }

    if (!authManager.isLogin()) {
      window.location = "/session/signin";
    }
    const filterGender = localStorage.getItem("filterGender");
    return (
      <div style={{height: "100%", width: "100%", overflow: "hidden"}}>
        <div style={{height: "100%", width: "100%", overflow: "auto"}}>
          <div style={{paddingBottom: "80px", textAlign: "center"}}>
            {
              showBottomNavigation() ?
              <Navigation  unreadCount={this.state.unreadCount}/> : ""
            }
            <Switch>
              <Route path="/camera" component={Camera} />
              <Route exact={true} path="/user/:userSn" component={UserDetail} />
              <Route exact={true} path="/feed/write" component={FeedWrite} />
              <Route exact={true} path="/feed/:scope" component={Feed} />
              <Route exact={true} path="/near-friend/:scope" component={NearFriends} />
              <Route exact={true} path="/near-friend/:scope/:filterGender" component={NearFriends} />
              <Route exact={true} path="/chat" component={Chat} />
              <Route exact={true} path="/chat/:channelUrl" component={ChatList} />
              <Route path="/message/:messageId" component={Message} />
              <Route path="/more/profile" component={MoreManageProfile} />
              <Route path="/more/account" component={MoreManageAccount} />
              <Route path="/more" component={More} />
              <Redirect from="/feed" to="/feed/all" />
              <Redirect from="/near-friend" to={`/near-friend/all/${filterGender}`} />
              <Redirect to="/feed" />
            </Switch>
          </div>
          {/*<Typography*/}
          {/*  variant="caption"*/}
          {/*  color={"textSecondary"}>*/}
          {/*  서비스 약관 | 개인정보처리방침 | 고객센터 | © 스윗커뮤니티*/}
          {/*</Typography>*/}
        </div>
      </div>
    )
  }
}
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Layout);
// export default Layout;

