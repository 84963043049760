import React from 'react';
import {Link, Route, Switch} from "react-router-dom";
import SessionSignIn from "./Session_signin";
import history from "../history.js";
import {withStyles} from "@material-ui/core/styles";
import SessionSignUp from "./SessionSignUp";
import SessionConfirm from "./Session_confirm";
import SessionPhone from "./Session_phone";
import SessionForgotPassword from "./Session_forgot_password";

const styles = (theme) => ({
  container: {
    position: "fixed",
    overflowY: "scroll",
    // background: "black",
    width: "100%",
    height: "100%",
    backgroundImage: "url('/session_back.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
  },
  inner: {
    width:"95%",
    maxWidth: "500px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  outer: {
    position: "relative",

    width:  "100%",
    height: "100%"
  }
});

class Session extends React.Component {
  componentDidMount() {

  }

  render() {
    const {classes} = this.props;
    return (
            <Switch>
              <Route exact path="/session/signin" component={SessionSignIn}/>
              <Route exact path="/session/signUp" component={SessionSignUp}/>
              <Route exact path="/session/confirm" component={SessionConfirm} />
              <Route exact path="/session/phone" component={SessionPhone} />
              <Route exact path="/session/forgot_pw" component={SessionForgotPassword} />
            </Switch>
    )
  }
};

export default withStyles(styles)(Session);

