import React from "react"
import history from "../history";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  rootMobile: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    height: "40px",
    zIndex: "1",
    display: 'flex',
    // flexDirection:'row'
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    background: "#e34132",
    color: "white",
  },
  itemBalanced: {
    flex: 1,
  },
  itemSelect: {
    background: 'white',
    color: '#e34132'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
});

class ScopeChanger extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.to = props.to;
    this.scope = props.scope;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    this.scope = nextProps.scope;
    this.to = nextProps.to;
    return true;
  }

  render() {
    const {classes} = this.props;

    const allUrl = this.to.replace(":scope", "all");
    const aroundUrl = this.to.replace(":scope", "around");
    const nearUrl = this.to.replace(":scope", "near");
    return (
      <div {...this.props}>
        <div className={"d-none d-lg-block"} style={{textAlign: 'center', marginBottom: '30px'}}>
          <div style={{width: "100%", maxWidth: "1600px", display: 'inline-block', textAlign: 'right'}}>
            <div className={classes.item} style={{display: 'flex'}}>
              <div style={{marginLeft: 'auto'}}></div>
              {this.props.data.map((item) => {
                if (item.onDraw) {
                  return item.onDraw();
                }
                return <span
                  key={item.scope}
                  style={{color: 'white', padding: '20px', fontSize: 18}}>
                  {item.onDraw ? item.onDraw() :
                    <Link
                      style={{
                        color: this.scope == item.scope ? 'white' : '#c8c8c8',
                        fontWeight: this.scope == item.scope ? 'bold' : 'normal'
                      }}
                      onClick={() => {
                        if (item.scope) {
                          history.replace({pathname: this.to.replace(":scope", item.scope)})
                        }
                      }}>{item.name}</Link>
                  }
                </span>
              })}
            </div>
          </div>
        </div>
        <div className={`d-block d-lg-none `}>
          <div className={`${classes.rootMobile}`}>
            {this.props.data.map((item) => {
              return (
                <div
                  key={item.scope || 'filter_gender'}
                  className={[
                    classes.item,
                    item.balanced == false ? '' : classes.itemBalanced,
                    this.scope == item.scope ? classes.itemSelect : ''].join(" ")}
                  onClick={() => {
                    if (item.scope) {
                      history.replace({pathname: this.to.replace(":scope", item.scope)})
                    }
                  }}>
                  {item.onDraw ? item.onDraw() :
                    <Typography style={{lineHeight: '40px', verticalAlign: 'center'}}
                                variant="subtitle2">{item.name}</Typography>
                  }
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ScopeChanger);
