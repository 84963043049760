import * as SendBird from "sendbird";

class Sb {
  constructor() {
    this.sb = new SendBird({appId: "103660D6-233B-41C5-8A10-D4B462DEC726"});
    this.channelList = [];
    this.channelListQuery = null;
    this.channelListener = null;
    this.currentChannlUrl = null;
    this.messageListener = null;
    this.unreadCountListener = {};

    const channelHandler = new this.sb.ChannelHandler();
    channelHandler.onMessageReceived = (channel, message) => {
      if (channel.url == this.currentChannlUrl && this.messageListener) {
        this.convertMessage(message);
        this.messageListener(message)
      } else {
        //TODO
        // notify popup
      }
    };
    channelHandler.onChannelChanged = (channel) => {
      const idx = this.channelList.findIndex(item => {
        return channel.url === item.url
      });

      if (idx > -1) {
        this.channelList.splice(idx, 1);
      }

      this.addChannel(channel);
      this.notifyChannelList();

      this.notifyUnreadCount();
    };
    channelHandler.onChannelDeleted = (channelUrl, channelType) => {
      const idx = this.channelList.findIndex(item => {
        return channelUrl === item.url
      });

      if (idx > -1) {
        this.channelList.splice(idx, 1);
      }
      this.notifyChannelList();
      this.notifyUnreadCount();
    };
    channelHandler.onMessageUpdated = ((channel, message) => {
      if (channel.url == this.currentChannlUrl && this.messageListener) {
        this.convertMessage(message);
        this.messageListener(message)
      }
    });

// Add this channel event handler to the `SendBird` instance.
    this.sb.addChannelHandler("HANDLER_ID", channelHandler);
  }

  async getUnreadCount() {
    return new Promise(resolve => {
      this.sb.getTotalUnreadMessageCount(function (count, error) {
        if (error) {
          console.log(error);
          return;
        }

        resolve(count)
      });
    })
  }

  notifyChannelList() {
    this.sortChannelList();
    this.channelList.map((channel) => {
      channel = this.getTargets(channel)
    });
    if (this.channelListener) {
      this.channelListener(this.channelList);
    }
  }

  getTargets(channel) {
      const to = JSON.parse(channel.name).to;
      const from = JSON.parse(channel.name).from;
      let toNickname = channel.memberMap[to] ? channel.memberMap[to].nickname : "";
      let toImage    = channel.memberMap[to] ? channel.memberMap[to].plainProfileUrl : "";
      let fromNickname = channel.memberMap[from] ? channel.memberMap[from].nickname : "";
      let fromImage    = channel.memberMap[from] ? channel.memberMap[from].plainProfileUrl : "";

      if (this.getCurrentUserId() == to) {
        channel.targetNickname = fromNickname
        channel.targetImage = fromImage
        channel.targetUersSn = from;
      } else {
        channel.targetNickname = toNickname
        channel.targetImage = toImage
        channel.targetUersSn = to;
      }
      return channel;
  }

  async notifyUnreadCount() {
    const count = await this.getUnreadCount();
    for (var key in this.unreadCountListener){
      this.unreadCountListener[key](count);
    }
  }

  addChannel(channel) {
    this.channelList.push(channel);
  }

  sortChannelList() {
    this.channelList.sort((a, b) => {
      const aLastMessageTime = a.lastMessage ? a.lastMessage.createdAt : new Date().getTime();
      const bLastMessageTime = b.lastMessage ? b.lastMessage.createdAt : new Date().getTime();
      return bLastMessageTime - aLastMessageTime
    });
  }

  getCurrentUserId() {
    return this.sb.getCurrentUserId()
  }

  async getChannel(channelUrl) {
    return new Promise((resolve, reject) => {
      this.sb.GroupChannel.getChannel(channelUrl, (channel, error) => {
        if (error) {
          return reject(error);
        }
        resolve(channel)
      })
    })
  }

  async sendMessage(channel, message) {
    const sb = this;
    return new Promise((resolve, reject) => {
      const params = new this.sb.UserMessageParams();
      params.message = message;


      channel.sendUserMessage(params, function(message, error) {
        if (error) {
          return reject(error);
        }
        sb.convertMessage(message)
        return resolve(message);
      })
    })
  }
  async sendImage(channel, imageUrl) {
    return this._sendImage(channel, imageUrl, false);
  }
  async sendSecureImage(channel, imageUrl) {
    return this._sendImage(channel, imageUrl, true);
  }
  async _sendImage(channel, imageUrl, secure) {
    const sb = this;
    return new Promise((resolve, reject) => {
      const params = new this.sb.UserMessageParams();
      params.message = "[사진]";
      params.data = JSON.stringify({
        "secure": secure,
        "type": "image/jpeg",
        "url": imageUrl,
        "secureUrl": imageUrl,
      });


      channel.sendUserMessage(params, function(message, error) {
        if (error) {
          return reject(error);
        }
        sb.convertMessage(message)
        return resolve(message);
      })
    })
  }

  async readAll(channel) {
    //TODO
    channel.markAsRead()
  }

  async getMessage(channel) {
    this.prevMessageListQuery = channel.createPreviousMessageListQuery();
    this.prevMessageListQuery.limit = 100;
    this.prevMessageListQuery.reverse = false;
    this.prevMessageListQuery.includeMetaArray = true;   // Retrieve a list of messages along with their metaarrays.
    this.prevMessageListQuery.includeReaction = true;    // Retrieve a list of messages along with their reactions.

    return await this.loadMoreMessage();
  }

  async loadMoreMessage() {
    return new Promise((resolve, reject) => {
      if (!this.prevMessageListQuery) {
        return reject(new Error("message query is not set"));
      }

      this.prevMessageListQuery.load((messages, error) => {
        if(error) {
          return reject(error);
        }
        messages.forEach((message) => {
          this.convertMessage(message);
        });
        resolve(messages);
      })
    })
  }

  async changeSecretCount(channel, messageId, image, secretCount) {
    if (secretCount < 0) {
      secretCount = 0;
    }

    const sb = this;
    return new Promise((resolve, reject) => {
      const params = new this.sb.UserMessageParams();
      params.message = "[사진]";
      params.data = JSON.stringify({
        "secure": true,
        "type": "image/jpeg",
        "url": image,
        "secureUrl": image,
        secretCount
      });

      channel.updateUserMessage(messageId, params, function(message, error) {
        if (error) {
          return reject(error);
        }

        sb.convertMessage(message);
        resolve(message)
      });
    })
  }

  async deleteSecretMessage(channel, messageId) {
    const sb = this;
    return new Promise((resolve, reject) => {
      const params = new this.sb.UserMessageParams();
      params.message = "[사진]";
      params.data = JSON.stringify({
        "secure": false,
        "type": "image/jpeg",
        "url": "https://sweetchats3.s3.ap-northeast-2.amazonaws.com/deleted_secret.png"
      });

      channel.updateUserMessage(messageId, params, function(message, error) {
        if (error) {
          return reject(error);
        }

        sb.convertMessage(message);
        resolve(message)
      });
    })
  }

  convertMessage(message) {
    message.isMe = message._sender.userId == this.getCurrentUserId()
  }

  setChannelListChangeListener(listener) {
    this.channelListener = listener;
    this.notifyChannelList();
  }
  setUnreadCountChangeListener(key, listener) {
    this.unreadCountListener[key] = listener;
    this.notifyUnreadCount();
  }
  removeUnreadCountChangeListener(key) {
    delete this.unreadCountListener[key]
  }
  removeChannelListChangeListener() {
    this.channelListQuery = null;
  }
  async enterChannel(channelUrl, listener) {
    this.currentChannlUrl = channelUrl;
    this.messageListener = listener

    return await this.getChannel(channelUrl)
  }
  leaveChannel() {
    this.currentChannlUrl = null;
    this.messageListener = null
  }

  async connect(userId, token) {
    return new Promise((resolve, reject) => {
      let connectUser = null;
      this.sb.connect(String(userId), token, function (user, error) {
        if (error) {
          console.log(error);
          return;
        }
        this.sb.getConnectionState()

        connectUser = user;
        this._initChannelList().then(channelList => {
          this.channelList = channelList;
          this.notifyChannelList();
          resolve(connectUser);
        })

        this.notifyUnreadCount();

      }.bind(this))
    })
  }

  async notify() {
    const channelList = await this._initChannelList();
    this.channelList = channelList;
    this.notifyChannelList();
    await this.notifyUnreadCount()
  }

  async _initChannelList() {
    this.channelList = [];
    this.channelListQuery = this._getNewChannelQuery();
    return await this._queryChannelList(this.channelListQuery);
  }

  async _queryChannelList(channelListQuery) {
    channelListQuery.includeEmpty = true;
    channelListQuery.order = 'latest_last_message'; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
    channelListQuery.limit = 100;    // The value of pagination limit could be set up to 100.

    return new Promise((resolve, reject) => {
      if (channelListQuery.hasNext) {
        channelListQuery.next(function (channelList, error) {
          if (error) {
            reject(error);
          }

          resolve(channelList);
        });
      }
      return Promise.resolve(null);
    })
  }

  _getNewChannelQuery() {
    return this.sb.GroupChannel.createMyGroupChannelListQuery();
  }
}

export default new Sb();
