import React, {useEffect} from 'react';
import sb from "../sendbird/sb";
import Typography from "@material-ui/core/Typography";
// RCE CSS
import 'react-chat-elements/dist/main.css';
// MessageBox component
import {icon, Dropdown, Navbar, SideBar, MessageBox, MessageList, Input, Button} from 'react-chat-elements';
import caller from "../call/caller";
import {withStyles} from "@material-ui/core/styles";
import Hangul from "../util/Hangul"
import Modal from "@material-ui/core/Modal";
import PictureView from "../modal/PictureView";
import ReactS3Uploader, {S3Response} from "react-s3-uploader"
import serverUrl from "../call/config";
import {Link} from "react-router-dom";
import history from "../history";
import {uploadImage} from "../util/uploadImage";
import myData from "../datastore/Mydata";
import Icon from "@material-ui/core/Icon";
import Sb from "../sendbird/sb";
import UserDetail from "../modal/UserDetail";
import {isMobile} from "react-device-detect";
import Camera from "./Camera";

const styles = (theme) => ({
  outer: {
    marginTop: '10px',
    width: "100%",
    display: 'flex',
    justifyContent: 'center'
  },
  inner: {
    maxWidth: "800px",
    width: "95%",
  },
  container: {
    width: "100%",
    display: "inline-block",
    textAlign: "left"
  },
  top: {
    // width: "100%",
    display: 'flex',
    justifyContent: 'space-between',
    height: "50px"
  },
  topCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'

  },
  topRight: {},
  middle: {
    overflowY: "scroll"
  },
  bottom: {
    width: "100%",
    height: "50px",
    display: 'flex'
  },
  bottomLeft: {
    width: "70px",
    height: "40px"
  },
  bottomMiddle: {
    flex: 1,
    height: "40px",
  },
  bottomLast: {
    height: "40px",
    width: "50px",
    float: "left",
  },
  messages: {
    overflowY: "auto",
  },
  sendMessage: {
    position: "fixed",
    bottom: "0",
    width: "100%",
  },
  paper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "90%",
    maxWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    display: 'flex',
    flexDirection: 'column',
    height: '150px',
    justifyContent: 'space-around'
  },
  uploadViewItem: {
    height: '40px',
    display: 'flex',
    backgroundColor: 'red',
    alignItems: 'center',
    justifyContent: 'center'

  }
});

class ChatList extends React.Component {
  timer;
  updateDimensions = () => {
    const viewport = window.visualViewport;
    const viewPortHeight = viewport.height;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      const prevViewPortHeight = this.state.viewPortHeight;
      const newViewPortHeight = viewPortHeight;

      this.setState({viewPortHeight: newViewPortHeight, middleHight: viewPortHeight - 100});
      window.scroll(0, newViewPortHeight - prevViewPortHeight);
      document.getElementById("yourScrollElementId").scrollTop = document.getElementById("yourScrollElementId").scrollHeight
      clearTimeout(this.timer);
    }, 0)
    // alert("!")
    // this.setState({middleHight:viewPortHeight-100});
  };
  windowOnScroll = (() => {
    window.scroll(0, 0);  // reset the scroll position to the top left of the document.
  });

  constructor(props) {
    super(props);

    this.channelUrl = props.match.params.channelUrl
    this.state = {
      profileModalOpen: false,
      profileModalUserSn: null,

      messages: [],
      channelLoad: false,
      inputs: "",
      pictureViewOpen: false,
      pictureUrl: null,
      secretPicture: false,
      unreadCount: 0,

      uploadViewOpen: false,
    };

    this.messageInput = React.createRef();
    this.channel = null;
    // this.lastCompositioning = null;
    this.unEndComposition = null;
  }

  async componentDidMount() {
    sb.setUnreadCountChangeListener("Chat", (count) => {
      this.setState({
        unreadCount: count
      })
    });

    window.visualViewport.addEventListener('resize', this.updateDimensions);
    window.addEventListener("scroll", this.windowOnScroll);

    const viewport = window.visualViewport;
    const viewPortHeight = viewport.height;
    this.setState({middleHight: viewPortHeight - 100});

    try {
      this.channel = await sb.enterChannel(this.channelUrl, (message) => {
        let messages = this.state.messages;
        let update = false;

        messages = messages.map(origin => {
          if (origin.messageId == message) {
            update = true;
            return message;
          }
          return origin;
        });

        if (!update) {
          messages.push(message);
        }

        this.setState({
          messages: messages
        });
        if (this.channel) {
          sb.readAll(this.channel);
        }
        document.getElementById("yourScrollElementId").scrollTop = document.getElementById("yourScrollElementId").scrollHeight
      });

      console.log(this.channel);
      const messages = await sb.getMessage(this.channel);
      await sb.readAll(this.channel);
      this.setState({
        channelLoad: true,
        messages: messages
      }, () => {
        document.getElementById("yourScrollElementId").scrollTop = document.getElementById("yourScrollElementId").scrollHeight
      });
    } catch (e) {
      console.log(e.stack);
      history.goBack()
    }
  }

  componentWillUnmount() {
    sb.removeUnreadCountChangeListener("Chat")
    sb.leaveChannel();
    clearTimeout(this.timer);
    window.visualViewport.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener("scroll", this.windowOnScroll);
  }

  getMessages() {
    let i = 0;
    return this.state.messages.map(message => {
      let opt = {
        id: message.messageId,
        position: message.isMe ? "right" : "left",
        date: message.createdAt,
      };
      if (!message.isMe) {
        opt.title = message._sender.nickname;
        opt.avatar = message._sender.profileUrl;
      }
      if (message.data) {
        const data = JSON.parse(message.data);
        const secure = data.secure;
        // const secureUrl = data.secureUrl;
        const url = data.url;
        let secretCount = data.secretCount;
        if (secretCount == null) {
          secretCount = 3;
        }
        opt.type = 'photo';
        if (secure) {
          const image = url;
          opt.data = {
            type: "secret",
            uri: "/secure_image.png",
            real: image,
            secretCount: secretCount
          };
        } else {
          const image = url;
          const auth = image.lastIndexOf("A.jpg") == image.length - 5
          opt.data = {
            type: auth ? "auth" : "normal",
            uri: image,
            real: image
          }
          if (auth) {
            opt.text = "인증사진입니다.";
          }
        }

        console.log(opt.text)
      } else {
        opt.type = "text"
        opt.text = message.message;
      }

      opt.style = {
        "text-size": "20px"
      }
      return opt
    })
  }

  async deleteChannel() {
    const response = await caller.post("chat/deleteMessage", {
      channelUrl: this.channelUrl
    });
    if (response.data.successYn) {
      console.log("leave!")
      history.goBack();
    } else {
      console.log("fail leave")
      history.goBack();
    }
  }

  pictureViewOpen(imageUrl, opt) {
    const {messageId, secret, secretCount} = opt;
    console.log(opt)
    this.setState({
      pictureViewOpen: true,
      pictureUrl: imageUrl,
      messageId: messageId,
      secretPicture: secret || false,
      secretCount: secret ? secretCount : null,
    })
  }

  async deleteSecretImage(messageId, image, secretCount) {
    const message = await sb.changeSecretCount(this.channel, messageId, image, secretCount-1)
    let messages = this.state.messages
    messages = messages.map((origin) => {
      if (origin.messageId == message.messageId) {
        return message;
      }
      return origin;
    })
    this.setState({
      messages: messages
    })
    // if (secretCount > 1) {
    //   const message = await sb.changeSecretCount(this.channel, messageId, image, secretCount-1)
    //   let messages = this.state.messages
    //   messages = messages.map((origin) => {
    //     if (origin.messageId == message.messageId) {
    //       return message;
    //     }
    //     return origin;
    //   })
    //   this.setState({
    //     messages: messages
    //   })
    // } else {
    //   console.log(`delete secret image in ${messageId}`)
    //   const message = await sb.deleteSecretMessage(this.channel, messageId);
    //   let messages = this.state.messages
    //   messages = messages.map((origin) => {
    //     if (origin.messageId == message.messageId) {
    //       return message;
    //     }
    //     return origin;
    //   })
    //   this.setState({
    //     messages: messages
    //   })
    // }
  }

  pictureViewClose() {
    this.setState({
      pictureViewOpen: false,
      pictureUrl: null
    })
  }

  uploadViewClose() {
    this.setState({
      uploadViewOpen: false
    })
  }

  async sendMessage() {
    const message = this.messageInput.value;
    if (!message) {
      return;
    }

    const lastChar = message.charAt(message.length - 1)
    if (Hangul.isHangul(lastChar)) {
      this.unEndComposition = lastChar
    }
    this.messageInput.value = "";
    this.messageInput.focus();
    const newMessage = await sb.sendMessage(this.channel, message)

    // this.state.messages.push(newMessage);
    this.setState({
      messages: this.state.messages.concat(newMessage)
    }, () => {
      this.test = true;
      document.getElementById("yourScrollElementId").scrollTop = document.getElementById("yourScrollElementId").scrollHeight
      setTimeout(() => {
        // console.log(Hangul.assemble("ㅎㅏㄴㅏ"));
        if (this.messageInput) {
          this.messageInput.focus();
        }
      }, 300)
    })
  }

  async uploadeImage(file) {
    const user = myData.getMyData();

    const url = await uploadImage(file, user.userSn, user.sex, 'message', 'G');
    return url;
  }

  async uploadeSecretImage(file) {
    const user = myData.getMyData();

    const url = await uploadImage(file, user.userSn, user.sex, 'message', 'G');
    return url;
  }

  render() {
    const {classes} = this.props;
    const getNickname = () => {
      if (this.channel) {
        let targets = sb.getTargets(this.channel);
        return (
          <Link onClick={() => {
            this.setState({profileModalOpen: true, profileModalUserSn: targets.targetUersSn});
          }}>
            <Typography
              variant="h5"
              color={"textPrimary"}>
              {targets.targetNickname}
            </Typography>
          </Link>
        )
      } else {
        return (
          <Typography
            variant="h5"
            color={"textPrimary"}>
            {"waiting.."}
          </Typography>
        )
      }

    }
    return (
      <div className={classes.outer}>
        <div className={classes.inner}>
          <div className={classes.container}>
            <div className={classes.top}>
              <div className={classes.topCenter}>
                <Icon onClick={() => {
                  history.goBack();
                }} fontSize={"small"}>arrow_back_ios</Icon>
                <Typography
                  style={{marginRight: '10px'}}
                  variant="h5"
                  color={"textPrimary"}>
                  {this.state.unreadCount || ''}
                </Typography>
                {getNickname()}
              </div>
              <div onClick={async () => {
                await this.deleteChannel();
              }} className={classes.topRight}>나가기
              </div>
            </div>
            <div id="yourScrollElementId" className={classes.middle} style={{height: this.state.middleHight}}>
              {this.state.channelLoad ?
                <MessageList
                  onClick={(message) => {
                    if (message.type == "photo") {
                      const secret = message.data.type == "secret";
                      const secretCount = message.data.secretCount;
                      this.pictureViewOpen(message.data.real, {
                        messageId: message.id,
                        secret: secret,
                        secretCount: secretCount,
                        auth: (message.data.type == "auth")
                      });
                      console.log(message);
                    }
                  }}
                  onTitleClick={(message) => {
                    console.log("title click")
                    console.log(message)
                    let targets = sb.getTargets(this.channel);
                    if (targets) {
                      this.setState({profileModalOpen: true, profileModalUserSn: targets.targetUersSn});
                    }
                  }}
                  className='message-list'
                  lockable={true}
                  toBottomHeight={'100%'}
                  dataSource={this.getMessages()}
                /> : "loading.."
              }
            </div>
            <div className={classes.bottom}>
              <div className={`filebox ${classes.bottomLeft}`}>
                <div className={`filebox`}>
                  <label
                    onClick={() => {
                      this.setState({
                        uploadViewOpen: true
                      })
                    }}
                    variant="contained"
                    component="label">
                    사진
                    {/*<input*/}
                    {/*  type="file"*/}
                    {/*  accept="image/*"*/}
                    {/*  hidden*/}
                    {/*  onChange={async (e) => {*/}
                    {/*    const file = e.target.files[0];*/}
                    {/*    const url = await this.uploadeImage(file)*/}
                    {/*    const newMessage = await sb.sendImage(this.channel, url);*/}
                    {/*    this.onPostSendMessage(newMessage);*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </label>
                </div>
              </div>
              <input className={classes.bottomMiddle}
                     style={{width: `${window.visualViewport.width - 120}px`}}
                     onKeyUp={(e) => {
                       if (e.key == 'Enter') {
                         this.sendMessage();
                         return;
                       }
                       if (this.unEndComposition) {
                         const lastCompositioning = this.unEndComposition;
                         const value = this.messageInput.value
                         let clear = false;
                         if (value.length > 1) {
                           clear = true;
                         }

                         const disLastCompositioning = Hangul.disassemble(lastCompositioning);
                         const disValue = Hangul.disassemble(value);

                         if (disValue.length < disLastCompositioning.length) {
                           this.unEndComposition = null;
                           return;
                         }
                         const newValue = [];
                         disValue.forEach((char, index) => {
                           if (newValue.length == 0 && disLastCompositioning[index] == char) {
                             return;
                           }
                           newValue.push(char);
                         })

                         this.messageInput.value = Hangul.assemble(newValue)
                         if (clear) {
                           this.unEndComposition = null;
                         }
                       }
                     }}
                // value={Hangul.assemble(this.state.inputs)}
                     ref={(input) => {
                       this.messageInput = input;
                     }}
                     placeholder="Type here..."
                     multiline={true}
                     autoComplete="off">

              </input>
              <Button className={classes.bottomLast}
                      text={"전송"}
                      onClick={async () => {
                        this.sendMessage();
                      }}>
              </Button>
              {/*<Button className={classes.bottomLast}*/}
              {/*        text={"전송"}*/}
              {/*        onClick={async () => {*/}
              {/*          const message = this.messageInput.value;*/}
              {/*          if (!message) {*/}
              {/*            return;*/}
              {/*          }*/}

              {/*          this.unEndComposition = this.lastCompositioning;*/}
              {/*          if (!this.unEndComposition) {*/}
              {/*            const lastChar = message.charAt(message.length - 1)*/}
              {/*            if (Hangul.isHangul(lastChar)) {*/}
              {/*              this.unEndComposition = lastChar*/}
              {/*            }*/}
              {/*          }*/}
              {/*          this.messageInput.value = "";*/}
              {/*          this.messageInput.focus();*/}
              {/*          const newMessage = await sb.sendMessage(this.channel, message)*/}
              {/*          this.onPostSendMessage(newMessage);*/}
              {/*        }}>*/}
              {/*</Button>*/}


            </div>
            <Modal
              open={this.state.pictureViewOpen}
              onClose={() => {
                this.pictureViewClose()
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              <div>
                <PictureView
                  id={this.state.messageId}
                  secret={this.state.secretPicture}
                  secretCount={this.state.secretCount}
                  imageUrl={this.state.pictureUrl}
                  onSecretClose={(id, image, secretCount) => {
                    this.deleteSecretImage(id, image, secretCount)
                  }}
                  onClose={() => {
                    this.pictureViewClose()
                  }}/>
              </div>
            </Modal>

            <Modal
              open={this.state.uploadViewOpen}
              onClose={() => {
                this.uploadViewClose()
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              <div className={classes.paper}>
                <div className={classes.uploadViewItem}>
                  <label
                    component="label">
                    <Typography
                      variant="h6"
                      color={"textPrimary"}>
                      일반사진
                    </Typography>
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={async (e) => {
                        this.uploadViewClose()

                        const file = e.target.files[0];
                        const url = await this.uploadeImage(file)
                        const newMessage = await sb.sendImage(this.channel, url);
                        this.onPostSendMessage(newMessage);
                      }}
                    />
                  </label>
                </div>
                <div className={classes.uploadViewItem}>
                  <label
                    component="label">
                    <Typography
                      variant="h6"
                      color={"textPrimary"}>
                      비밀사진
                    </Typography>
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={async (e) => {
                        this.uploadViewClose()

                        const file = e.target.files[0];
                        const url = await this.uploadeImage(file)
                        const newMessage = await sb.sendSecureImage(this.channel, url);
                        this.onPostSendMessage(newMessage);
                      }}
                    />
                  </label>
                </div>
                {isMobile ? (
                  <div className={classes.uploadViewItem}>
                    <label
                      onClick={() => {
                        // //TODO
                        // // go camera view
                        // console.log("인증사진")
                        // history.push({
                        //   pathname: "/camera"
                        // })
                        this.setState({cameraModalOpen: true})
                      }}
                      component="label">
                      <Typography
                        variant="h6"
                        color={"textPrimary"}>
                        인증사진
                      </Typography>
                    </label>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Modal>
          </div>
        </div>
        <Modal
          open={this.state.profileModalOpen}
          onClose={() => {
            this.setState({profileModalOpen: false, profileModalUserSn: null})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <UserDetail
            onClose={() => {
              this.setState({profileModalOpen: false, profileModalUserSn: null})
            }}
            userSn={this.state.profileModalUserSn}/>
        </Modal>

        <Modal
          open={this.state.cameraModalOpen}
          onClose={() => {
            this.setState({cameraModalOpen: false, uploadViewOpen: false})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <Camera
            onClose={() => {
              this.setState({cameraModalOpen: false, uploadViewOpen: false})
            }}
            onUpload={async (url) => {
              const newMessage = await sb.sendImage(this.channel, url);
              this.onPostSendMessage(newMessage);
            }}
          />
        </Modal>
      </div>
    )
  }

  onPostSendMessage(newMessage) {
    this.state.inputs = "";

    // this.state.messages.push(newMessage);
    this.setState({
      messages: this.state.messages.concat(newMessage)
    }, () => {
      this.test = true;
      document.getElementById("yourScrollElementId").scrollTop = document.getElementById("yourScrollElementId").scrollHeight
    })
  }
}

export default withStyles(styles)(ChatList);
