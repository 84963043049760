import React from 'react';
import {Nav, NavItem} from 'reactstrap';
import {Link, NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faHome,
  faUserCircle,
  faCommentDots,
  faUser,
  faSearchLocation, faSitemap, faGripVertical
} from '@fortawesome/free-solid-svg-icons';
import Badge from "@material-ui/core/Badge";

const tabs = [{
  route: "/feed",
  icon: faHome,
  label: "동네소식"
}, {
  route: "/near-friend",
  icon: faSearchLocation,
  label: "회원"
}, {
  route: "/chat",
  icon: faCommentDots,
  label: "메시지",
  badge: true
}, {
  route: "/more",
  icon: faGripVertical,
  label: "더보기"
}];

const Navigation = (props) => {
  function getBottomNavClassName(route) {
    return window.location.pathname.startsWith(route) ?
      "nav-link bottom-nav-link active" : "nav-link bottom-nav-link"
  }

  return (
    <div>
      {/*//피시*/}
      <nav className="navbar navbar-expand-md navbar-light d-none d-lg-block sticky-top" role="navigation">
        <div className="container-fluid" style={{maxWidth: "1600px"}}>
          <a className="navbar-brand" href="/feed">HOME</a>
          <Nav className="ml-auto">
            {
              tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                <NavLink to={tab.route} className="nav-link" activeClassName="active">
                  {tab.label}
                </NavLink>
              </NavItem>
              ))
            }
          </Nav>
        </div>
      </nav>
      {/*모바일*/}
      <nav style={{backgroundColor: "white"}} className="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav" role="navigation">
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            {
              tabs.map((tab, index) => (
                <NavItem key={`tab-${index}`}>
                  {/*<NavLink to={tab.route} className="nav-link bottom-nav-link" activeClassName="active">*/}
                  <Link className={getBottomNavClassName(tab.route)} to={tab.route} replace>
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                      {
                        tab.badge ?
                        <Badge badgeContent={props.unreadCount} color="secondary">
                          <FontAwesomeIcon size="lg" icon={tab.icon}/>
                        </Badge> :
                        <FontAwesomeIcon size="lg" icon={tab.icon}/>
                      }

                      <div className="bottom-tab-label">{tab.label}</div>
                    </div>
                  </Link>
                  {/*</NavLink>*/}
                </NavItem>
              ))
            }
          </div>
        </Nav>
      </nav>
    </div>
  )
};

export default Navigation;
