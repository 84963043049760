import React from 'react';
import caller from "../call/caller";
import {Auth} from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core/styles";
import history from "../history";
import Button from "@material-ui/core/Button";
import {Checkbox} from "@material-ui/core";
import {Link} from "react-router-dom";
import moment from "moment";

const SessionSignUp = (props) => {
  const [agree, setAgree] = React.useState(false);
  const [user, setUser] = React.useState({
    id: '',
    pw: '',
    pwConfirm: '',
    nickName: '',
    sex: '',
    profile: '',
    old: '',
    phoneNumberMiddle: '',
    phoneNumberLast: '',
    listCategorySn: [3],
    profileImage: 'https://sweetchats3.s3.ap-northeast-2.amazonaws.com/iconfinder_profle_1055000.png'
  })

  const checkNickname = async (nickname) => {
    const {data} = await caller.post('users/checkNickname', {
      nickname: nickname,
    });

    if (data.successYn) {
      const {enable} = data.result;
      if (enable) {
        //TODO
        return true;
      } else {
        alert('닉네임이 중복입니다.');
      }
    } else {
      if (data.statusCode == 'contentsContainsForbiddenWord') {
        alert('허용되지 않는 단어가 포함되어있습니다.');
      } else {
        alert(
          '알 수 없는 에러가 발생하였습니다. 잠시 후 다시 시도하여 주세요.',
        );
      }
    }
    return false;
  }

  const checkProfile = async (profile) => {
    const {data} = await caller.post('users/checkProfile', {
      profile: profile,
    });
    if (data.successYn) {
      //TODO
      return true;
    } else {
      if (data.statusCode == 'contentsContainsForbiddenWord') {
        alert('허용되지 않는 단어가 포함되어있습니다.');
      } else {
        alert('알 수 없는 에러가 발생하였습니다.');
      }
    }
    return false;
  }

  const checkId = async (id) => {
    const {data} = await caller.post('users/checkId', {
      id: id,
    });

    if (data.successYn) {
      const {enable} = data.result;
      if (enable) {
        //TODO
        return true;
      } else {
        alert('아이디가 중복입니다.');
      }
    } else {
      if (data.statusCode == '') {
      }
      alert(
        '알 수 없는 에러가 발생하였습니다. 잠시 후 다시 시도하여 주세요.',
      );
    }
    return false;
  }

  const signUp = async () => {
    try {
      const {
        id,
        pw,
        pwConfirm,
        nickName,
        sex,
        profile,
        old,
        phoneNumberMiddle,
        phoneNumberLast,
        listCategorySn,
        profileImage
      } = user;


      if (!nickName || !sex || !old || !id || !pw || !pwConfirm || !phoneNumberMiddle || !phoneNumberLast || !listCategorySn || !profileImage) {
        return alert("모든 항목을 입력해주세요")
      }
      if (pw != pwConfirm) {
        return alert("비밀번호가 다릅니다.")
      }

      if (pw.length < 8) {
        return alert("비밀번호를 8자 이상으로 설정해주세요.")
      }
      if (!agree) {
        return alert("이용약관에 동의해주세요!")
      }

      if (phoneNumberMiddle.length != 4 || phoneNumberLast.length != 4) {
        alert('휴대폰번호를 입력해주세요.');
        return;
      }
      const phoneNumber = `+82010${phoneNumberMiddle}${phoneNumberLast}`;

      if (!await checkId(id)) {
        return;
      }
      if (!await checkNickname(nickName)) {
        return;
      }
      if(!await checkProfile(profile)) {
        return;
      }

      const result = await Auth.signUp({
        username: id,
        password: pw,
        attributes: {
          phone_number: phoneNumber,
        },
        clientMetadata: {
          data: JSON.stringify({
            id: id,
            pw: pw,
            appKey: 1,
            user,
            listCategorySn: listCategorySn,
          }),
        },
      });
      return result.user;
    } catch (e) {
      if (e.code === 'UsernameExistsException') {
        return alert('아이디가 중복입니다.');
      }
      if (e.code === 'UserLambdaValidationException') {
        const message = e.message.replace('PreSignUp failed with error ', '');
        switch (message) {
          case 'nicknameTooLong.':
            return alert('닉네임이 너무 깁니다.');
          case 'duplicateId.':
            return alert('아이디가 중복입니다.');
          case 'duplicateNickname.':
            return alert('닉네임이 중복입니다.');
        }
      }
      console.log(e);
      await alert('알 수 없는 오류가 발생하여서 회원가입을 할 수 없습니다. ');
      return null;
    }
  }

  const olds = () => {
    const olds = [];
    for (let i=0 ; i<80 ; i++) {
      olds.push(i+20);
    }
    return olds;
  }
  const {classes} = props;
  return (
    <div className={classes.container}>
      <br/>
      <div style={{margin: '10px', maxWidth:'600px', display:'inline-block', textAlign:'left'}}>
        <div>스윗티 서비스에 오신 것을 환영합니다.</div>
        <div>회원의 정보는 최고의 보안을 자랑하는 AWS Cognito에 의해 보호됩니다.</div>
        <div>회원 탈퇴시 아래의 모든 정보는 즉각 파기합니다.</div>
        <br/>
        <div>* 아이디</div>
        <TextField
          name="id" className={classes.formItem}
          value={user.id} onChange={(e) => {setUser({...user, id: e.target.value})}}
          id="outlined-error-helper-text"
          label="* 아이디를 입력해주세요."
          variant="outlined"
        />
        <br/>
        <div>* 비밀번호</div>
        <TextField
          type="password"
          value={user.pw} onChange={(e) => {setUser({...user, pw: e.target.value})}}
          name="pw" className={classes.formItem}
          id="outlined-error-helper-text"
          label="* 비밀번호를 입력해주세요."
          // helperText={this.state.error ? "아이디와 비밀번호가 잘못 되었습니다." : ""}
          variant="outlined"
        />
        <TextField
          type="password"
          value={user.pwConfirm} onChange={(e) => {setUser({...user, pwConfirm: e.target.value})}}
          name="pw_confirm" className={classes.formItem}
          id="outlined-error-helper-text"
          label="* 비밀번호를 다시 한 번 입력해주세요."
          // helperText={this.state.error ? "아이디와 비밀번호가 잘못 되었습니다." : ""}
          variant="outlined"
        />
        <br/>
        <div>* 닉네임</div>
        <TextField
          value={user.nickName} onChange={(e) => {setUser({...user, nickName: e.target.value})}}
          name="nickname" className={classes.formItem}
          id="outlined-error-helper-text"
          label="* 닉네임을 입력해주세요."
          variant="outlined"
        />
        <br/>
        <br/>
        <div>* 성별을 선택해주세요</div>
        <div style={{display: 'flex', justifyContent: 'space-around', height: '60px'}}>
          <Button style={{margin: 4, backgroundColor: user.sex == 'M' ? 'blue' : '#b8caff', color: 'white'}} onClick={() => {
            setUser({...user, sex: 'M'})
          }} className={classes.formItem}>남자</Button>
          <Button style={{margin: 4, color: 'white', backgroundColor: user.sex == 'F' ? 'red' : '#f8c1c1'}} onClick={() => {
            setUser({...user, sex:'F'})
          }} className={classes.formItem}>여자</Button>
        </div>
        <br/>
        <br/>
        <div>소개글</div>
        <TextField
          value={user.profile} onChange={(e) => {setUser({...user, profile: e.target.value})}}
          name="profile" className={classes.formItem}
          id="outlined-error-helper-text"
          label="소개글을 입력해주세요."
          variant="outlined"
        />
        <br/>
        <br/>
        <div>* 나이</div>
        <select style={{height: '60px'}} className={classes.formItem}
            value={user.old} onChange={(e) => {setUser({...user, old: e.target.value})}}
        >
          <option value="0">* 나이를 선택해주세요.</option>
          {
            olds().map(old => {
              return (<option key={old} value={old}>{old}</option>)
            })
          }
        </select>
        <br/><br/>
        <div>* 핸드폰번호를 입력해주세요.</div>
        <div style={{display: 'flex', justifyContent: 'space-around', height: '60px'}}>
          <TextField
            style={{margin:5}}
            className={classes.formItem}
            id="outlined-error-helper-text"
            value={'010'}
            disabled={true}
            variant="outlined"
          />
          <TextField
            style={{margin:5}}
            value={user.phoneNumberMiddle} onChange={(e) => {setUser({...user, phoneNumberMiddle: e.target.value})}}
            name="phone_number1" className={classes.formItem}
            id="outlined-error-helper-text"
            variant="outlined"
          />
          <TextField
            style={{margin:5}}
            value={user.phoneNumberLast} onChange={(e) => {setUser({...user, phoneNumberLast: e.target.value})}}
            name="phone_number2" className={classes.formItem}
            id="outlined-error-helper-text"
            variant="outlined"
          />
        </div>

        <br/>

        <div>
          <Checkbox checked={agree} onChange={(e) => {setAgree(e.target.checked)}}/>
          * 다음사항에 모두 동의합니다.</div>
        <div><Link onClick={() => {
          document.location.href = 'https://www.sweet.chat/terms'
        }}>- 이용약관</Link></div>
        <div><Link onClick={() => {
          document.location.href = 'https://www.sweet.chat/terms'
        }}>- 개인정보취급방침</Link></div>
        <div><Link onClick={() => {
          document.location.href = 'https://www.sweet.chat/terms'
        }}>- 위치기반서비스이용약관</Link></div>

        <br/>
        <div style={{display: 'flex', justifyContent: 'space-around', height: '60px'}}>
          <Button style={{backgroundColor: 'black', color: 'white'}} onClick={async () => {
            const result = await signUp();
            if (!result) {
              return;
            }

            const {
              id,
              pw,
            } = user;

            await Auth.signIn(id, pw)
            const token = (await Auth.currentSession())
              .getAccessToken()
              .getJwtToken();

            history.push({
              pathname: "/session/confirm"
            })

            // const {data} = await caller.post('users/loginWithCognito', {
            //   token: token,
            // });
            //
            // if (data.successYn) {
            //   let token = data.result.token;
            //   let refreshToken = data.result.refreshToken;
            //   let expireIn = data.result.expireIn;
            //
            //   caller.defaults.headers.common['Authorization'] = token;
            //   localStorage.setItem("accessToken", token);
            //   localStorage.setItem("refreshToken", refreshToken);
            //   localStorage.setItem("tokenExpireTime", moment().add(expireIn, "seconds"));
            //
            //   history.push({
            //     pathname: "/session/confirm"
            //   })
            // } else {
            //   const {statusCode} = data;
            //   switch (statusCode) {
            //     case 'incorrectPw':
            //       return alert('비밀번호가 잘못되었습니다.');
            //     case 'No User':
            //       return alert('아이디가 존재하지 않습니다.');
            //     case 'Network Error':
            //       return alert('서버와의 접속이 원활하지 않습니다.');
            //   }
            // }
          }} className={classes.formItem}>회원가입</Button>
        </div>
      </div>
    </div>
  )
}

const styles = (theme) => ({
  container: {
    textAlign: 'center',
    position: "fixed",
    overflowY: "auto",
    // // background: "black",
    // margin:'100px',
    width: "100%",
    height: "100%",
    backgroundImage: "url('/session_back.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
  },
  logo: {
    paddingTop: "10px",
    width: "100%"
  },
  title: {
    color: "white",
    marginTop: "20px",
    marginBottom: "60px"
  },
  form: {
    height: "230px",
    marginBottom: "100px"
  },
  formItem: {
    marginBottom: "12px",
    width: "100%"
  },
  loginButton: {
    position: "static",
    bottom: "0px",
    height: "50px",
    width: "100%"
  },
  popup: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "95%",
    maxWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
})
export default withStyles(styles)(SessionSignUp);
