// import React from 'react';
import * as axios from "axios";
import serverUrl from "./config";

const caller = axios.create({
  // baseURL: 'https://www.sweet.chat',
  baseURL: serverUrl,
  // baseURL: 'http://localhost:3000',
});
const accessToken = localStorage.getItem("accessToken");
caller.defaults.headers.common['APP-KEY'] = 200;
if (accessToken) {
  caller.defaults.headers.common['Authorization'] = accessToken;
} else {
  delete caller.defaults.headers.common['Authorization'];
}

export default caller;