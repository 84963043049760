import React from 'react';
import history from '../history'
import caller from "../call/caller";
import {withStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import serverUrl from "../call/config";
import Modal from "@material-ui/core/Modal";
import Icon from "@material-ui/core/Icon";
import {Link} from "react-router-dom";
import myData from "../datastore/Mydata";

const styles = (theme) => ({
  outer: {
    // paddingTop: "0px",
    marginTop: '10px',
    width: "100%",
  },
  inner: {
    maxWidth: "800px",
    width: "95%",
    display: "table",
    margin: "0 auto",
  },
  container: {
    // backgroundColor: '#8b8b8b',
    width: "100%",
    display: "inline-block",
    textAlign: "left"
  },
  profile: {
    backgroundColor: 'white',
    // border: "1px solid",
    marginBottom: '10px',
    height: "120px",
    display: 'flex',
    alignItems: 'center'
  },
  item: {
    backgroundColor: 'white',
    marginTop: '10px',
    height: '40px',
    display: 'flex',
    alignItems: 'center'
  },
  itemIcon: {margin: '13px', marginLeft: '5px'},
  profileImage: {
    // marginTop: "25px",
    // marginLeft: "25px",
    height: "90px",
    width: "90px",
    margin: '20px'
    // float: "left",
  },
  profileContent: {
    textAlign: "left",
    display: 'flex',
    // alignItems:'center',
    justifyContent: 'center',
    flexDirection: 'column'
    // marginLeft: "130px",
    // marginTop: "70px",
    // transform: "translate(0, -50%)",
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    // border: "1px solid",
  },
  button: {},
  popup: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "95%",
    maxWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
});

class More extends React.Component {
  state = {
    id: "",
    point: 0,
    user: {},
    alertMessageOpen: false
  };
  onConfirm = null;
  alertMessage = null;

  constructor(prop) {
    super(prop);
  }

  async componentDidMount() {
    const me = await this.getMyData();
    const {user} = me;
    const {id, point} = me.account;
    const {
      disposition,
      grade,
      isExposeGrade,
      level,
      nickName,
      old,
      profile,
      profileImage,
      profileThumbnailUrl,
      sex,
      userSn
    } = user;

    this.setState({
      id: id,
      point: point,
      user: user
    })
    console.log(me);
  }

  async getMyData() {
    return myData.getMyData()
    // const response = await caller.post("/users/readProfile");
    // if (response.data.successYn) {
    //   let result = response.data.result;
    //   return result;
    // }
    // console.log(response.data.statusCode);
    // return null;
  }

  confirmMessageOepn = async (message, onConfirm) => {
    this.onConfirm = onConfirm;
    this.alertMessage = message
    return new Promise(resolve => {
      this.setState({
        alertMessageOpen: true
      })
    })
  }
  alertMessageOpen = (message) => {
    this.onConfirm = null;
    this.alertMessage = message
    this.setState({
      alertMessageOpen: true
    })
  };

  alertMessageClose = () => {
    this.onConfirm = null;
    this.alertMessage = null;
    this.setState({
      alertMessageOpen: false
    })
  }

  async attendance() {
    const response = await caller.post("users/attendance")
    if (response.data.successYn) {
      let result = response.data.result;
      console.log(result);

      const updatedPoint = result.updatedPoint;
      this.alertMessageOpen(`${updatedPoint / 10} 하트가 지급되었습니다.`)
    } else {
      let statusCode = response.data.statusCode;
      console.log(statusCode)
      if (statusCode == "alreadyAttendance") {
        this.alertMessageOpen(`이미 출석체크를 완료하였습니다.`)
      }
    }
  }

  render() {
    const {classes} = this.props;
    const {user, id, point} = this.state;
    console.log(this.state);
    return (
      <React.Fragment>
        <div className={classes.outer}>
          <div className={classes.inner}>
            <div className={classes.container}>
              <div className={classes.profile}>
                <Avatar className={classes.profileImage}
                        src={user.profileThumbnailUrl}
                />
                <div className={classes.profileContent}>
                  <Typography variant="subtitle1">
                    {user.nickName} ({user.old}세) <br/>
                    {user.profile} <br/>
                  </Typography>
                  <Typography variant="subtitle2">
                    신뢰점수 : {user.grade}
                  </Typography>
                </div>
              </div>
              <div className={classes.buttonContainer}>

                <div className={classes.item} onClick={() => {
                  history.push({
                    pathname: "/more/profile"
                  })
                }}>
                  <Icon className={classes.itemIcon}> account_circle</Icon>
                  <Link style={{color: 'black'}}>
                    <Typography variant="subtitle1">
                      프로필 관리
                    </Typography>
                  </Link>
                </div>
                <div className={classes.item} onClick={() => {
                  history.push({
                    pathname: "/more/account"
                  })
                }}>
                  <Icon className={classes.itemIcon}> lock </Icon>
                  <Link style={{color: 'black'}}>
                    <Typography variant="subtitle1">
                      계정 관리
                    </Typography>
                  </Link>
                </div>
                <div className={classes.item} onClick={() => {
                  this.attendance();
                }}>
                  <Icon className={classes.itemIcon}> access_time </Icon>
                  <Link style={{color: 'black'}}>
                    <Typography variant="subtitle1">
                      출석체크
                    </Typography>
                  </Link>
                </div>
                <div className={classes.item} onClick={() => {
                  const url = `${serverUrl}/billingPage.html?token=${localStorage.getItem("accessToken")}`;
                  document.location.href = url;
                }}>
                  <Icon className={classes.itemIcon}> favorite </Icon>
                  <Link style={{color: 'black'}}>
                    <Typography variant="subtitle1">
                      하트 충전
                    </Typography>
                  </Link>
                </div>
                <div className={classes.item}>
                  <Icon className={classes.itemIcon}> mail </Icon>
                  <a style={{color: 'black'}} href={"mailto:random.chatting.69@gmail.com"}>
                    {/*<button className={classes.button}>문의하기</button>*/}
                    <Typography variant="subtitle1">
                      문의
                    </Typography>
                  </a>
                </div>
                <div className={classes.item}>
                  <Icon className={classes.itemIcon}> description </Icon>
                  <a style={{color: 'black'}} target="_blank" href={`${serverUrl}/terms`}>
                    {/*<button className={classes.button}>문의하기</button>*/}
                    <Typography variant="subtitle1">
                      이용약관
                    </Typography>
                  </a>
                </div>
              </div>
              <Typography
                variant="caption"
                color={"textSecondary"}>
                <a href={"https://www.sweet.chat/terms"}>서비스 약관</a> | <a href={"https://www.sweet.chat/terms"}>개인정보처리방침</a> | <a href={"mailto:random.chatting.69@gmail.com"}>고객센터</a> | <a href={"mailto:random.chatting.69@gmail.com"}>© 스윗커뮤니티</a>
              </Typography>

              <Modal
                open={this.state.alertMessageOpen}
                onClose={this.alertMessageClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div className={classes.popup}>
                  {this.alertMessage}
                  {this.onConfirm ? <div style={{textAlign: "right"}} onClick={() => {
                    this.onConfirm()
                  }}>확인</div> : <div></div>}
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

;

export default withStyles(styles)(More);
