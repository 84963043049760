import React from 'react';
import ScopeChanger from "../component/ScopeChanger"
import {withStyles} from "@material-ui/core";
import caller from "../call/caller";
import Avatar from "@material-ui/core/Avatar";
import {Link} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import history from "../history";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Divider from "@material-ui/core/Divider";
import SendMessageView from "../modal/SendMessage";
import Modal from "@material-ui/core/Modal";
import FilterGenderView from "../modal/FilterGender";
import {geolocated} from "react-geolocated";
import LocationPermissionGuide from "../component/LocationPermissionGuide";
import UserDetail from "../modal/UserDetail";


const styles = (theme) => ({
  // outer: {
  //   // paddingTop:"10px",
  //   width: "100%"
  // },
  // inner: {
  //   maxWidth: "800px",
  //   display: "table",
  //   margin: "0 auto",
  // },
  // container: {
  //   maxWidth: "800px",
  //   width: "95%",
  //   display: "inline-block",
  //   textAlign: "left"
  // },
  outer: {
    // paddingTop: "0px",
    width: "100%",
  },
  inner: {
    maxWidth: "800px",
    display: "table",
    margin: "0 auto",
  },
  container: {
    maxWidth: "800px",
    width: "95%",
    display: "inline-block",
    textAlign: "left"
  },
  genderFilter: {
    color: "white",
    float: "left",
    width: "10%",
    height: "40px",
    background: "#e34132"
  },

  wrapper: {
    width: "100%",
    overflow: "hidden",
    marginTop: "10px",
    marginBottom: "10px"
  },
  left: {
    // border: "1px solid",
    paddingTop: "5px",
    width: "60px",
    height: "60px",
    float: "left",
  },
  middle: {
    height: "60px",
    overflow: "hidden"
  },
  last: {
    width: "30px",
    float: "right",
  },
  middleRow1: {
    overflow: "hidden",
    // border: "1px solid",
    height: "30px",
  },
  middleRow2: {
    overflow: "hidden",
    // border: "1px solid",
    height: "30px",
  },
  avatar: {
    height: "50px",
    width: "50px"
  }
});


class NearFriends extends React.Component {
  constructor(props) {
    super(props);

    this.scope = props.match.params.scope;
    this.filterGender = props.match.params.filterGender;

    this.state = {
      friends: [],
      needLocationPermission: false,
      filterGenderOpen: false,
      sendMessageOpen: false,
      sendMessageTargetUserSn: null,

      profileModalUserSn: null,
      profileModalOpen: false,
    };
  }

  sendMessageOpen = (userSn) => {
    this.setState({
      sendMessageOpen: true,
      sendMessageTargetUserSn: userSn
    })
  };
  sendMessageClose = () => {
    this.setState({
      sendMessageOpen: false,
      sendMessageTargetUserSn: null
    })
  };
  filterGenderClose = () => {
    this.setState({
      filterGenderOpen: false
    })
  };

  async componentDidMount() {
    window.addEventListener("focus", this.onFocus)
    window.scroll(0, 0);  // reset the scroll position to the top left of the document.
    this.getFriends()
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus)
  }

  onFocus() {
    console.log('near frineds focus')
  }

  async shouldComponentUpdate(nextProps, nextState, nextContext) {
    const newScope = nextProps.match.params.scope;
    const filterGender = nextProps.match.params.filterGender;
    localStorage.setItem("filterGender", filterGender);

    const newCoords = nextProps.coords;
    const strLocation = caller.defaults.headers.common['Request-Location'];
    const location = strLocation && JSON.parse(strLocation)
    if (newCoords) {
      caller.defaults.headers.common['Request-Location'] = JSON.stringify({
        latitude: newCoords.latitude,
        longitude: newCoords.longitude
      });
    }

    let reData = false;
    if (this.scope != newScope || this.filterGender != filterGender) {
      reData = true;
    }
    if (location == null && newCoords != null) {
      reData = true;
    }

    this.scope = newScope;
    this.filterGender = filterGender;

    if (reData) {
      this.setState({
        friends: []
      }, async () => {
        await this.getFriends()
      });
    }

    return true;
  }

  async getFavorite() {
    const response = await caller.post("users/readFollow", {})
    if (response.data.successYn) {
      const followings = response.data.result;
      this.setState({
        friends: followings
      })

    } else {
      //TODO
      // alert
    }

  }

  async getFriends() {
    if (this.scope == "favorite") {
      return await this.getFavorite();
    }

    let data = {
      count: 30,
      criteria: {
        scope: this.scope,
        sex: ["M", "F"]
      }
    };
    if (this.state.friends.length > 0) {
      data.lastUserLoginTime = this.state.friends[this.state.friends.length - 1].lastLoginTime
    }

    if (this.filterGender) {
      data.criteria.sex = [this.filterGender == "male" ? "M" : "F"]
    }

    const strLocation = caller.defaults.headers.common['Request-Location'];
    console.log(`strLocation is ${strLocation}`);
    if (strLocation) {
      const location = JSON.parse(strLocation)
      if (location) {
        data.criteria.location = {
          latitude: location.latitude,
          longitude: location.longitude
        }
      }
    }

    let needLocationPermission = data.criteria.scope != "all" && data.criteria.location == null;
    if (needLocationPermission) {
      this.setState({
        needLocationPermission: true
      });
      return [];
    } else {
      this.setState({
        needLocationPermission: false
      });
    }
    const response = await caller.post("users/readList", data)

    if (response.data.successYn) {
      const prevScroll = window.pageYOffset;
      const friends = response.data.result;

      this.setState({
        friends: this.state.friends.concat(friends)
      }, () => {
        window.scrollTo(0, prevScroll)
      });
      return friends;
    } else {
      //TODO
      return [];
    }
  }

  getGender(sex) {
    switch (sex) {
      case "M":
        return "남자";
      case "F":
        return "여자";
      case "G":
        return "게이";
      case "L":
        return "레즈비언";
    }
    return "";
  }

  getFilter() {
    if (this.filterGender) {
      return `/${this.filterGender}`
    }
    return "";
  }

  differTimeToString(differTime) {
    if (!differTime) {
      return null;
    }
    differTime = differTime.replace("s", "초 전")
    differTime = differTime.replace("m", "분 전")
    differTime = differTime.replace("H", "시간 전")
    differTime = differTime.replace("D", "일 전")
    differTime = differTime.replace("W", "주 전")
    differTime = differTime.replace("M", "개월 전")
    differTime = differTime.replace("Y", "년 전")
    return differTime;
  }

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        <ScopeChanger
          data={[
            {
              name: "전체",
              scope: "all"
            },
            {
              name: "주변",
              scope: "around"
            },
            {
              name: "근처",
              scope: "near"
            },
            {
              name: "즐겨찾기",
              scope: "favorite"
            },
            {
              onDraw: (isSelect) => {
                return <Icon
                  key={'filter_gender'}
                  style={{
                    color: 'white',
                    height: '100%',
                    margin: '0px',
                    lineHeight: '40px',
                    marginRight: '20px',
                    background: "#e34132",
                  }}
                  onClick={() => {
                    this.setState({filterGenderOpen: true})
                  }}
                  fontSize={"default"}>
                  wc
                </Icon>
              },
              balanced: false,
            }
          ]}
          scope={this.scope} to={`/near-friend/:scope${this.getFilter()}`}
        />
        <div className={classes.outer}>
          <div className={classes.inner}>
            {this.state.needLocationPermission ? <LocationPermissionGuide/> : ""}
            {this.state.friends.map((friend) => {
              const userSn = friend.userSn;
              const nickName = friend.nickName;
              const sex = friend.sex;
              const old = friend.old;
              const profile = friend.profile;
              const profileImage = friend.profileImage;
              const profileThumbnailUrl = friend.profileThumbnailUrl;
              const lastLoginTime = friend.lastLoginTime;
              const distance = friend.distance;
              const differTime = friend.differTime;

              return (
                <div key={`key-${userSn}`} className={classes.container}>
                  <div className={classes.wrapper}>
                    <div className={classes.left}>
                      <Link onClick={() => {
                        this.setState({
                          profileModalUserSn: userSn,
                          profileModalOpen: true
                        })
                      }}>
                        <Avatar className={classes.avatar} alt={nickName} src={profileImage}/>
                      </Link>
                    </div>
                    <div className={classes.last}>
                      <Icon onClick={() => {
                        this.sendMessageOpen(userSn)
                      }}> message </Icon>
                    </div>
                    <div className={classes.middle}>
                      <div className={classes.middleRow1}>
                        <Typography
                          color={sex == "F" || sex == "L" ? "secondary" : "primary"}
                          variant="subtitle1">
                          {this.scope == "favorite" ?
                            `${nickName} (${old}세)` :
                            `${nickName} (${old}세) (${distance || "???"}km)`}

                        </Typography>
                      </div>
                      <div className={classes.middleRow2}>
                        <Typography
                          // style={{lineHeight:"25px"}}
                          color={"textSecondary"}
                          variant="subtitle1">
                          {this.scope == "favorite" ? this.differTimeToString(differTime) : profile}

                        </Typography>
                      </div>
                    </div>
                  </div>
                  <Divider/>
                </div>
              )
            })}
            {this.state.friends.length == 0 ? "" :
              <button style={{width: "100%", maxWidth: "800px", height: "50px"}} onClick={() => {
                this.getFriends();
              }}>더보기</button>}
          </div>
        </div>
        <Modal
          open={this.state.profileModalOpen}
          onClose={() => {
            this.setState({profileModalOpen: false, profileModalUserSn: null})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <UserDetail
            onClose={() => {
              this.setState({profileModalOpen: false, profileModalUserSn: null})
            }}
            userSn={this.state.profileModalUserSn}/>
        </Modal>

        <Modal
          open={this.state.sendMessageOpen}
          onClose={this.sendMessageClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div>
            {this.state.sendMessageTargetUserSn ?
              <SendMessageView
                targetUserSn={this.state.sendMessageTargetUserSn}
                onClose={() => {
                  this.sendMessageClose()
                }}/> : ""}
          </div>
        </Modal>
        <Modal
          open={this.state.filterGenderOpen}
          onClose={this.filterGenderClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div>
            <FilterGenderView
              onSelect={(gender) => {
                history.replace({pathname: `/near-friend/${this.scope}/${gender}`})
              }}
              filterGender={this.filterGender}
              onClose={() => {
                this.filterGenderClose()
              }}/>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(withStyles(styles)(NearFriends));
