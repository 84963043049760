import React from 'react';

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.messageId = props.match.params.messageId
  }

  componentDidMount() {
    //TODO
    // get message detail => image url
    // show image
    // 0.5sec
    // hide image

    //TODO check show image
    // 다시 이미지 로드할 수 없게..
  }

  render() {
    return (
      <div></div>
    );
  }
}

export default Message;