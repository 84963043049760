import React from 'react';
import {Link, Route, Switch} from "react-router-dom";
import caller from "../call/caller";
import history from "../history";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as moment from "moment";
import Modal from "@material-ui/core/Modal";
import {Auth} from "aws-amplify";

const styles = (theme) => ({
  container: {
    position: "fixed",
    // overflowY: "scroll",
    // background: "black",
    width: "100%",
    height: "100%",
    backgroundImage: "url('/session_back.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
  },
  inner: {
    width: "95%",
    maxWidth: "500px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  outer: {
    position: "relative",

    width: "100%",
    height: "100%"
  },
  logo: {
    paddingTop: "10px",
    width: "100%"
  },
  title: {
    color: "white",
    marginTop: "20px",
    marginBottom: "60px"
  },
  form: {
    height: "230px",
    marginBottom: "100px"
  },
  formItem: {
    marginBottom: "12px",
    width: "100%"
  },
  loginButton: {
    position: "static",
    bottom: "0px",
    height: "50px",
    width: "100%"
  },
  popup: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "95%",
    maxWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
})

class SessionSignIn extends React.Component {
  state = {
    id: "",
    pw: "",
    error: "",
    alertMessageOpen: false
  };
  onConfirm = null;
  alertMessage = null;

  componentDidMount() {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      history.push({
        pathname: "/"
      })
    }
  }

  login = async () => {
    // console.log(`id: ${this.state.id}`);
    // console.log(`pw: ${this.state.pw}`);
    const {id, pw} = this.state;
    try {
      await Auth.signOut()
      const user = await Auth.signIn(id, pw);
      console.log(user);
      if (!user.attributes.phone_number) {
        history.push({
          pathname: "/session/phone"
        })
        return;
      }
      if (!user.attributes.phone_number_verified) {
        history.push({
          pathname: "/session/phone"
        })
        return;
      }
    } catch (e) {
      switch (e.message) {
        case "User does not exist.":
          alert("잘못된 아이디입니다.")
          return;
        case "Incorrect username or password.":
          alert("잘못된 비밀번호입니다.")
          return;
      }
      switch (e.code) {
        case "UserNotFoundException":
          alert("잘못된 아이디입니다.")
          return;
      }
      alert(e.message);
      return;
    }

    const token = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    const {data} = await caller.post('users/loginWithCognito', {
      token: token,
    });

    if (data.successYn) {
      let token = data.result.token;
      let refreshToken = data.result.refreshToken;
      let expireIn = data.result.expireIn;

      caller.defaults.headers.common['Authorization'] = token;
      localStorage.setItem("accessToken", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("tokenExpireTime", moment().add(expireIn, "seconds"));

      window.location.replace("/")
    } else {
      const {statusCode} = data;
      switch (statusCode) {
        case 'incorrectPw':
          return alert('비밀번호가 잘못되었습니다.');
        case 'No User':
          return alert('아이디가 존재하지 않습니다.');
        case 'Network Error':
          return alert('서버와의 접속이 원활하지 않습니다.');
      }
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      error: ""
    });
  };

  confirmMessageOepn = async (message, onConfirm) => {
    this.onConfirm = onConfirm;
    this.alertMessage = message
    return new Promise(resolve => {
      this.setState({
        alertMessageOpen: true
      })
    })
  }
  alertMessageOpen = (message) => {
    this.onConfirm = null;
    this.alertMessage = message
    this.setState({
      alertMessageOpen: true
    })
  };

  alertMessageClose = () => {
    this.onConfirm = null;
    this.alertMessage = null;
    this.setState({
      alertMessageOpen: false
    })
  }

  render() {
    const {classes} = this.props;

    const id = "lxgf3846";
    const pw = "lxgf3846";
    return (
      <div className={classes.container}>
        <div className={classes.outer}>
          <div className={classes.inner}>
            <Container fixed>
              <img className={classes.logo} src={"/logo.png"}/>
              <Typography
                className={classes.title}
                variant="h5">
                새로운 친구를 만나세요.
              </Typography>
              <div className={classes.form}>
                <TextField
                  error={this.state.error}
                  name="id" className={classes.formItem} value={this.state.id} onChange={this.onChange}
                  id="outlined-error-helper-text"
                  label="ID"
                  variant="outlined"
                />
                <TextField
                  type="password"
                  error={this.state.error}
                  name="pw" className={classes.formItem} value={this.state.pw} onChange={this.onChange}
                  id="outlined-error-helper-text"
                  label="PASSWORD"
                  helperText={this.state.error ? "아이디와 비밀번호가 잘못 되었습니다." : ""}
                  variant="outlined"
                />
                {/*<TextField*/}
                {/*  error={this.state.error ? "error" : ""}*/}
                {/*  name="id" className={classes.formItem} value={this.state.id} onChange={this.onChange} id="outlined-basic1" label="ID" variant="outlined"/>*/}
                {/*<TextField*/}
                {/*  error={this.state.error ? "error" : ""}*/}
                {/*  name="pw" className={classes.formItem} value={this.state.pw} onChange={this.onChange} id="outlined-basic2" label="PW" variant="outlined"/>*/}
                <Button className={classes.loginButton} variant="contained" color="secondary" onClick={this.login}>
                  로그인
                </Button>
              </div>

              <Typography
                variant="subtitle2"
                color={"textSecondary"}>
                계정이 없다면
                <Link to={"/session/signUp"}> 회원가입</Link>을 진행해주세요.
              </Typography>

              <Typography
                variant="subtitle2"
                color={"textSecondary"}>
                비밀번호를 분실한 경우
                <Link to={"/session/forgot_pw"}> 비밀번호 찾기</Link>를 진행해주세요.
              </Typography>

              <Typography
                variant="caption"
                color={"textSecondary"}>
                <a href={"https://www.sweet.chat/terms"}>서비스 약관</a> | <a href={"https://www.sweet.chat/terms"}>개인정보처리방침</a> | <a href={"mailto:random.chatting.69@gmail.com"}>고객센터</a> | <a href={"mailto:random.chatting.69@gmail.com"}>© 스윗커뮤니티</a>
              </Typography>

              <Modal
                open={this.state.alertMessageOpen}
                onClose={this.alertMessageClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div className={classes.popup}>
                  {this.alertMessage}
                  {this.onConfirm ? <div style={{textAlign: "right"}} onClick={() => {
                    this.onConfirm()
                  }}>확인</div> : <div></div>}
                </div>
              </Modal>
            </Container>
          </div>
        </div>
      </div>
    )
  }
};

export default withStyles(styles)(SessionSignIn);

