import React from 'react';
import history from "../history.js";
import Sb from "../sendbird/sb";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Badge from "@material-ui/core/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Row from "reactstrap/es/Row";
import UserDetail from "../modal/UserDetail";
import Modal from "@material-ui/core/Modal";

const styles = (theme) => ({
  // card: {
  //   lineHeight: '1.5em',
  //   height: '3em',
  //   overflow: 'hidden'
  // },

  outer: {
    paddingTop: "10px",
    width: "100%"
  },

  inner: {
    width: '100%',
    maxWidth: "800px",
    display: "table",
    margin: "0 auto",
  },
  container: {
    maxWidth: "800px",
    width: "95%",
    display: "inline-block",
    textAlign: "left"
  },
  wrapper: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  left: {
    paddingTop: "5px",
    width: "60px",
    height: "60px",
    float: "left",
  },
  middle: {
    height: "60px",
    overflow: "hidden"
  },
  last: {
    width: "30px",
    float: "right",
  },
  avatar: {
    // marginTop: "20px",
    // marginBottom: "20px",
    height: "50px",
    width: "50px"
  }
});

class Chat extends React.Component {
  constructor({props}) {
    super(props);
    this.state = {
      channels: [],

      profileModalOpen: false,
      profileModalUserSn: null,
    }
  }

  componentDidMount() {
    window.scroll(0, 0);  // reset the scroll position to the top left of the document.
    Sb.setChannelListChangeListener(channels => {
      this.setState({channels: channels})
    });
  }

  render() {
    const channels = this.state.channels;
    const {classes} = this.props;

    if (channels.length == 0) {
      return (
        <div style={{marginTop:'200px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
          메시지가 없습니다. <br/>
          지금 내 주변 사람들에게 쪽지를 보내보세요.
        </div>
      )
    }
    return (
      <React.Fragment>
        <div className={classes.outer}>
          <div className={classes.inner}>
            {
              channels.map(channel => {
                return (
                  <div className={classes.container} key={channel.url}>
                    <div className={classes.wrapper} >
                      <div className={classes.left}>
                        <Badge badgeContent={channel.unreadMessageCount} color="secondary">
                        <Avatar onClick={() => {
                          this.setState({profileModalOpen: true, profileModalUserSn: channel.targetUersSn});
                        }} className={classes.avatar} alt={channel.targetNickname} src={channel.targetImage}/>
                        </Badge>
                      </div>
                      <div style={{background: "red"}} className={classes.last}>
                        <Typography
                          variant="subtitle1"
                          color={"textSecondary"}>
                          {/*23분 전*/}
                          {/*{channel.lastMessage.message}*/}
                        </Typography>
                      </div>
                      <div onClick={
                        () => {
                          history.push({pathname: `/chat/${channel.url}`})
                        }}
                           className={classes.middle}>
                        <div style={{height: "30px"}}>
                          <Typography
                            variant="subtitle1"
                            color={"textPrimary"}>
                            {channel.targetNickname || "차단되었거나 탈퇴한 사용자입니다."}
                          </Typography>
                        </div>

                        <div style={{height: "30px"}}>
                          <Typography
                            variant="subtitle1"
                            color={"textSecondary"}>
                            {channel.lastMessage.message}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Divider/>
                  </div>
                )
              })
            }
          </div>
        </div>
        <Modal
          open={this.state.profileModalOpen}
          onClose={() => {
            this.setState({profileModalOpen: false, profileModalUserSn: null})
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <UserDetail
            onClose={() => {
              this.setState({profileModalOpen: false, profileModalUserSn: null})
            }}
            userSn={this.state.profileModalUserSn}/>
        </Modal>
        {/*<input type="file" onChange={this.uploadFile} />*/}

      </React.Fragment>
    );
  }
}


export default withStyles(styles)(Chat);
