import * as React from "react";
import Modal from "@material-ui/core/Modal";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import caller from "../call/caller";
import history from "../history";

const styles = (theme) => ({
  paper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "95%",
    maxWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  messageForm: {
    marginTop: "30px",
    marginBottom: "10px",
    width: "100%"
  },
  bottom: {
    textAlign:"right",
    // position: "absolute",
    // right:"10px",
    // bottom:"10px"
  }
});

class SendMessageView extends React.Component {
  state = {
    message: "",
    reaminPoint: 0
  };

  async componentDidMount() {
    history.push();
    this.backListener = history.listen((location, action) => {
      if (action === "POP") {
        this.props.onClose()
      }
    });

    const {point} = await this.getAccount()
    const lastMessage = localStorage.getItem("lastMessage");
    this.setState({
      reaminPoint: point,
      message: lastMessage
    })
  }

  componentWillUnmount() {
    history.goBack();
    this.backListener();
  }

  async getAccount() {
    const response = await caller.post("/users/readProfile")
    if (response.data.successYn) {
      const account = response.data.result.account
      console.log(account)
      return account;
    }
    return null;
  }

  async sendMessage() {
    const {targetUserSn} = this.props;
    let message = this.state.message;
    const response = await caller.post("/chat/startMessage", {
      message : message,
      targetUserSn : targetUserSn
    });
    localStorage.setItem("lastMessage", message);

    if (response.data.successYn) {
      console.log(response);
      this.props.onClose();
    } else {
      console.log(response);
    }
  }

  render() {
    const {classes} = this.props;
    const {reaminPoint} = this.state;

    console.log(this.props)
    return (
      <div className={classes.paper}>
        <Typography
          variant="h4"
          color={"textPrimary"}>
          메시지 보내기
        </Typography>
        <TextField
          value={this.state.message}
          onChange={(e) => {this.setState({
            message: e.target.value
          })}}
          autoFocus={true}
          className={classes.messageForm}
          label="메시지를 입력하세요."
          multiline
          rows={3}
          variant="outlined"
        />
        <Typography
          variant="subtitle2"
          color={"textSecondary"}>
          남은하트 {reaminPoint/10}개<br />
          쪽지 전송시 하트 5개가 소진됩니다.<br />
          부적절한 쪽지 전송시 이용이 제한됩니다.
        </Typography>
        <div className={classes.bottom}>
          <Button color={"secondary"} onClick={this.props.onClose}>
            취소
          </Button>
          <Button color={"primary"} onClick={() => {this.sendMessage()}}>
            전송
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SendMessageView);
