import React from 'react';
import {Link, Route, Switch} from "react-router-dom";
import caller from "../call/caller";
import history from "../history";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as moment from "moment";
import Modal from "@material-ui/core/Modal";
import {Auth} from "aws-amplify";
import authManager from "../AuthManager";

const styles = (theme) => ({
  container: {
    position: "fixed",
    // overflowY: "",
    // background: "black",
    width: "100%",
    height: "100%",
    backgroundImage: "url('/session_back.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
  },
  inner: {
    width: "99%",
    maxWidth: "600px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  outer: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  formItem: {
    marginBottom: "12px",
    width: "100%"
  }
})

const SessionForgotPassword = ({classes}) => {
  const [inputId, setInputId] = React.useState('');
  const [id, setId] = React.useState('');

  const [confirmCode, setConfirmCode] = React.useState('');
  const [password, setPassword] = React.useState('');

  const checkSignIn = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setInputId(user.username)
      setId(user.username);
      return;
    } catch (e) {
      if (e == 'not authenticated') {
        //pass
      } else {
        history.push({
          pathname: "/"
        })
        return;
      }
    }
  }

  React.useEffect(() => {
    if (id) {
      tryForgotPassword(id);
    }
  }, [id])

  const tryForgotPassword = async (userId) => {
    try {
      await Auth.forgotPassword(userId);
    } catch (e) {
      if (e && e.code == 'LimitExceededException') {
        alert('너무 자주 요청할 수 없습니다. 5분 뒤 다시 시도해주세요.');
        history.push({
          pathname: "/"
        })
        return;
      } else if (e && e.code == 'UserNotFoundException') {
        // 더 이상 호출 안됨 (서버사이드)
        // alert('사용자를 찾을 수 없습니다.');
        // this.props.navigation.pop();
      } else {
        console.log('error in forgotPassword');
        console.log(e);
      }
    }
  }

  const conformForgotPasswordCode = async (userId, password, confirmCode) => {
    const {data} = await caller.post('users/resetPassword', {
      userId,
      password,
      confirmCode,
    });

    if (data.successYn) {
      await alert('성공적으로 비밀번호를 재설정하였습니다.');
      history.push({
        pathname: "/"
      })
      return;
    } else {
      console.log(data.statusCode);
      if (data.statusCode == 'invalidCode') {
        return alert('인증번호가 잘못되었습니다.');
      }
      if (data.statusCode == 'attemptLimitExceeded') {
        return alert(
          '너무 자주 요청하였습니다. 30분 뒤 다시 시도하여주세요.',
        );
      }
      if (data.statusCode == 'PASSWORD_MUST_GT_5') {
        return alert('비밀번호는 6자리 이상으로 설정해주세요.');
      }
      if (data.statusCode == 'invalidCode') {
        return alert('잘못된 코드입니다.');
      }
      await alert('error');
    }
  }
  React.useEffect(() => {
    checkSignIn()
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.outer}>
        <div className={classes.inner}>
          <Container fixed>
            {id == '' ? (
              <>
                <div style={{textAlign: 'center'}}>로그인 ID를 입력해주세요.</div>

                <div style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  height: '60px',
                  marginTop: '10px',
                  marginBottom: '20px'
                }}>
                  <TextField
                    style={{margin: 5}}
                    className={classes.formItem}
                    value={inputId} onChange={(e) => {
                    setInputId(e.target.value)
                  }}
                    id="outlined-error-helper-text"
                    variant="outlined"
                  />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-around', height: '60px'}}>
                <Button style={{backgroundColor: 'black', color: 'white'}} onClick={async () => {
                 setId(inputId)
                }} className={classes.formItem}>확인</Button>
                </div>
              </>
              ) : (<>
              <div style={{textAlign: 'center'}}>등록된 휴대전화로 인증번호가 전송되었습니다.</div>
              <div style={{textAlign: 'center'}}>인증문자를 받지 못하신 경우</div>
              <div style={{textAlign: 'center'}}>없는 아이디거나 잘못된 아이디일 수 있습니다.</div>
              <br/>
              <div style={{textAlign: 'center'}}>인증번호와 새로운 비밀번호를 입력해주세요.</div>
              <Typography
                style={{marginLeft:'2px'}}
                variant="subtitle2"
                color={"textSecondary"}>
                아이디
              </Typography>
              <TextField
                name="id" className={classes.formItem}
                value={id}
                disabled={true}
                id="outlined-error-helper-text"
                variant="outlined"
              />
              <Typography
                style={{marginLeft:'2px'}}
                variant="subtitle2"
                color={"textSecondary"}>
                인증번호
              </Typography>

              <TextField
                name="id" className={classes.formItem}
                value={confirmCode} onChange={(e) => {setConfirmCode(e.target.value)}}
                id="outlined-error-helper-text"
                label="인증번호를 입력해주세요."
                variant="outlined"
              />
              <Typography
                style={{marginLeft:'2px'}}
                variant="subtitle2"
                color={"textSecondary"}>
                비밀번호
              </Typography>
              <TextField
                type="password"
                name="password" className={classes.formItem}
                value={password} onChange={(e) => {setPassword(e.target.value)}}
                id="outlined-error-helper-text"
                label="비밀번호를 입력해주세요."
                variant="outlined"
              />
              <div style={{display: 'flex', justifyContent: 'space-around', height: '60px'}}>
                <Button style={{backgroundColor: 'black', color: 'white'}} onClick={async () => {
                  if (!password || !confirmCode) {
                    alert("항목을 입력해주세요.")
                    return;
                  }
                  conformForgotPasswordCode(id, password, confirmCode)
                }} className={classes.formItem}>확인</Button>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-around', height: '60px'}}>
                <Button style={{backgroundColor: '#ff8686', color: 'white'}} onClick={async () => {
                }} className={classes.formItem}>인증번호 재전송</Button>
              </div>
            </>)}
          </Container>
        </div>
      </div>
    </div>
  )
};

export default withStyles(styles)(SessionForgotPassword);

