import React from 'react';
import {Link, Route, Switch} from "react-router-dom";
import caller from "../call/caller";
import history from "../history";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as moment from "moment";
import Modal from "@material-ui/core/Modal";
import {Auth} from "aws-amplify";

const styles = (theme) => ({
  container: {
    position: "fixed",
    // overflowY: "",
    // background: "black",
    width: "100%",
    height: "100%",
    backgroundImage: "url('/session_back.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
  },
  inner: {
    width: "95%",
    maxWidth: "600px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  outer: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  formItem: {
    marginBottom: "12px",
    width: "100%"
  }
})

const SessionConfirm = ({classes}) => {
  const [confirmCode, setConfirmCode] = React.useState('')
  const checkSignIn = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log(user);
      if (!user) {
        history.push({
          pathname: "/"
        })
      }
      return true;
    } catch (e) {
      history.push({
        pathname: "/"
      })
    }
    return false;
  }
  React.useEffect(() => {
    const init = async () => {
      if (await checkSignIn()) {
        await request();
      }
    };
    init();
  }, [])

  const request = async() => {
    try {
      await Auth.verifyCurrentUserAttribute('phone_number');
      return true;
    } catch (e) {
      if (e.code == 'LimitExceededException') {
        alert(
          '인증코드를 전송할 수 없습니다. 인증코드를 너무 자주 요청할 수 없습니다. 잠시 후 다시 시도해주세요.',
        );
      }
      return false;
    }
  }

  const confirm = async (confirmCode) => {
    if (!confirmCode) {
      return;
    }
    try {
      const result = await Auth.verifyCurrentUserAttributeSubmit(
        'phone_number',
        confirmCode,
      );
      console.log(result);
      if (result) {
        await alert('인증에 성공하였습니다.');
        history.replace("/")

        // const token = (await Auth.currentSession())
        //   .getAccessToken()
        //   .getJwtToken();
      } else {
        if (result.code == 'CodeMismatchException') {
          await alert('잘못된 코드입니다.');
        }
      }
    } catch(e) {
      if (e.code == 'CodeMismatchException') {
        await alert('잘못된 코드입니다.');
      }
      console.log(e);
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.outer}>
        <div className={classes.inner}>
          <Container fixed>
            <div style={{textAlign:'center'}}>인증번호가 전송되었습니다.</div>
            <TextField
              style={{marginTop: '10px'}}
              name="id" className={classes.formItem}
              value={confirmCode}
              onChange={(e) => {setConfirmCode(e.target.value)}}
              id="outlined-error-helper-text"
              label="인증번호를 입력해주세요."
              variant="outlined"
            />
            <div style={{display: 'flex', justifyContent: 'space-around', height: '60px'}}>
              <Button style={{backgroundColor: 'black', color: 'white'}} onClick={async () => {
                confirm(confirmCode)
              }} className={classes.formItem}>확인</Button>
            </div>
            <div>
              인증번호를 받지 못하셨나요?
            </div>
            <div>
              <Link onClick={async () => {
                if (await request()) {
                  alert("전송되었습니다.");
                }
              }}>재전송</Link>
            </div>
            <div>
              <Link onClick={() => {
                history.push({
                  pathname: "/session/phone"
                })
              }}>핸드폰번호 재설정</Link>
            </div>

          </Container>
        </div>
      </div>
    </div>
  )
};

export default withStyles(styles)(SessionConfirm);

