import React from 'react';
import moment from "moment";
import './App.css';
import {Router, Switch, Route, BrowserRouter} from "react-router-dom";
import Session from './pages/Session';
import history from "./history.js";
import Layout from "./pages/Layout";
import Sb from "./sendbird/sb";
import caller from "./call/caller";
import authManager from "./AuthManager";
import {Auth as AWSAuth} from "aws-amplify";
import myData from "./datastore/Mydata";

function App() {
  return (
    <Auth signIn={"/session/signin"} freePaths={["/session"]}>
      <Router history={history}>
        <Switch>
          <Route path="/session" component={Session}/>
          <Route path="/" component={Layout}/>
        </Switch>
      </Router>
    </Auth>
  );
}

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prepare: false
    };
  }

  async componentDidMount() {
    let signIn = this.props.signIn;
    if (!signIn) {
      throw new Error("로그인 페이지의 경로가 설정되어있지 않습니다.")
    }

    const status = this.getTokenStatus();
    if (status === "EXPIRED") {
      await this.refreshToken();
    }

    if (this.isLogin()) {
      this.runTimer();

      const myData = await this.getMyData();
      const user = await this.connectSb(myData.userSn, myData.sbAccessToken);
    } else {
      await AWSAuth.signOut()
      this.clearData();
      this.goToSignIn();
    }

    this.setState({
      prepare: true
    });
  }

  runTimer() {
    const {tokenExpireTime} = this.getData();
    this.setTimerRefreshToken(tokenExpireTime);
  }

  goToSignIn() {
    let signIn = this.props.signIn;
    let redirect = true;
    this.props.freePaths.map((freePath) => {
      if (window.location.pathname.startsWith(freePath) ||
        window.location.pathname.startsWith(signIn)) {
        redirect = false;
      }
    });

    if (redirect) {
      window.location = signIn;
    }
  }

  setTimerRefreshToken(tokenExpireTime) {
    let expireDate = moment(tokenExpireTime);
    let now = moment();

    var duration = moment.duration(expireDate.diff(now));
    var durationSec = duration.asSeconds();

    let timeout = (durationSec - 5) * 1000;
    console.log(`token refresh after ${parseInt((timeout / 1000) / 60)}min ${parseInt((timeout / 1000) % 60)}sec`);

    setTimeout(async () => {
      const success = await this.refreshToken()
      if (success) {
        const {tokenExpireTime} = this.getData();
        this.setTimerRefreshToken(tokenExpireTime);
      }
    }, timeout);
  }

  refreshToken = async () => {
    console.log("refresh token");
    const {refreshToken} = this.getData();
    if (!refreshToken) {
      return false;
    }

    const response = await caller.post("/users/refreshToken", {
      refreshToken
    });

    if (response.data.successYn) {
      let token = response.data.result.token;
      let expireIn = response.data.result.expireIn;
      let tokenExpireTime = moment().add(expireIn, "seconds");

      caller.defaults.headers.common['Authorization'] = token;
      localStorage.setItem("accessToken", token);
      localStorage.setItem("tokenExpireTime", tokenExpireTime);

      return true;
    }

    return false;
  };

  componentWillUnmount() {
    console.log("APP COMPONENT WILL UNMOUNT");
    clearTimeout(this.timer);
  }

  async connect() {

  }

  async connectSb(userSn, sbAccessToken) {
    const user = await Sb.connect(userSn, sbAccessToken);
    return user
  }

  async getMyData() {
    //cache
    myData.getMyData()

    return myData.getSbAccessToken()
  }

  getTokenStatus() {
    return authManager.getTokenStatus()
  }

  getData() {
    return authManager.getData()
  }

  clearData() {
    return authManager.clearData()
  }

  isLogin() {
    return authManager.isLogin()
  }

  render() {
    const {prepare} = this.state;
    return (
      <React.Fragment>
        {prepare ? this.props.children : "waiting.."}
      </React.Fragment>
    );
  }
}

export default App;
