import history from "./history.js";
import React from 'react';
import caller from "./call/caller.js";
import moment from "moment";

class AuthManager {
  getTokenStatus() {
    const {accessToken, tokenExpireTime, refreshToken} = this.getData();

    if (!accessToken || !tokenExpireTime || !refreshToken) {
      return "EMPTY";
    }

    let expireDate = moment(tokenExpireTime);
    let now = moment();
    if (expireDate <= now) {
      return "EXPIRED";
    }

    return "LIVE"
  }

  getData() {
    let accessToken = localStorage.getItem("accessToken");
    let tokenExpireTime = localStorage.getItem("tokenExpireTime");
    let refreshToken = localStorage.getItem("refreshToken");
    return {accessToken, tokenExpireTime, refreshToken}
  }

  clearData() {
    caller.defaults.headers.common['Authorization'] = null;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("tokenExpireTime");
    localStorage.removeItem("refreshToken");
  }

  isLogin() {
    return this.getTokenStatus() === "LIVE"
  }
}

const authManager = new AuthManager();
export default authManager;