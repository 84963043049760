import caller from "../call/caller";

class Mydata {
  getMyData = () => {
    try {
      let cache = this._getLocal();
      if (cache) {
        return cache;
      }
    } catch(e) {
      console.log(e);
    }
    return this._getRemote()
  }

  resetMyData = () => {
    return this._getRemote()
  }

  _clearLocal = () => {
    localStorage.removeItem('myData');
  }
  _getLocal = () => {
    const myData = localStorage.getItem("myData");
    if (myData) {
      return JSON.parse(myData);
    }
    return null;
  }
  _setLocal = (me) => {
    localStorage.setItem("myData", JSON.stringify(me));
  }

  async _getRemote() {
    const user = await this.getUser()
    this._setLocal(user)

    return user;
  }

  async getSbAccessToken() {
    const response = await caller.post("/users/me");
    const result = response.data.result;
    const {userSn, sbAccessToken} = result;
    return {userSn, sbAccessToken};
  }
  async getUser() {
    const response = await caller.post("/users/readProfile");
    if (response.data.successYn) {
      let result = response.data.result;
      return result;
    }
    console.log(response.data.statusCode);
    return null;
  }
}
const myData = new Mydata();
export default myData;
