import * as React from "react";
import Modal from "@material-ui/core/Modal";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import caller from "../call/caller";
import history from "../history";

const styles = (theme) => ({
  paper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "95%",
    maxWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  item: {
    marginTop:"4px",
    marginBottom:"4px",
    textAlign:"center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width:"100%",
    height:"50px"
  },
  select: {
    background:"blue"
  },
  bottom: {
    textAlign:"right",
    // position: "absolute",
    // right:"10px",
    // bottom:"10px"
  }
});

class FilterGenderView extends React.Component {
  state = {
    message: ""
  };

  async componentDidMount() {
    history.push();
    this.backListener = history.listen((location, action) => {
      if (action === "POP") {
        this.props.onClose()
      }
    });
  }

  componentWillUnmount() {
    history.goBack();
    this.backListener();
  }

  render() {
    const {classes} = this.props;
    const {filterGender} = this.props; //male or female

    console.log(`filterGender is ${filterGender}`)
    return (
      <div className={classes.paper}>
        <div>
          <Button variant="contained" color={`${!filterGender ? "primary" : ""}`} onClick={()=>{this.props.onSelect("")}} className={`${classes.item}`}>모두</Button>
          <Button variant="contained" color={`${filterGender=="male" ? "primary" : ""}`} onClick={()=>{this.props.onSelect("male")}} className={`${classes.item} `}>남자</Button>
          <Button variant="contained" color={`${filterGender=="female" ? "primary" : ""}`} onClick={()=>{this.props.onSelect("female")}} className={`${classes.item} `}>여자</Button>
        </div>
        <div className={classes.bottom}>
          <Button
            onClick={this.props.onClose}
            color={"primary"}>확인</Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FilterGenderView);
