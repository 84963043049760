import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import history from "../history";

const styles = (theme) => ({
  paper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "100%",
    maxWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
  },
  ball: {
    width: '50px',
    height: '50px',
    backgroundImage: "http://localhost:3000/authentication_image.png",
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    animation: 'bounce 0.5s'
    // animation-direction: alternate;
    // animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
    // animation-iteration-count: infinite;
    }
});
class PictureView extends React.Component {
  state = {
    imageUrl: null,
    show: true
  };

  componentDidMount() {
    this.onBlur = () => {
      this.props.onClose();
    }
    window.addEventListener("blur", this.onBlur)
    history.push();
    this.backListener = history.listen((location, action) => {
      console.log("picture")
      console.log(history.location);
      if (action === "POP") {
        this.props.onClose()
      }
    });

    const {imageUrl, secret, id, secretCount} = this.props;
    console.log(`imageUrl is ${imageUrl}`)
    this.setState({
      imageUrl: imageUrl
    });
    this.id = id
    this.imageUrl = imageUrl;
    this.secret = secret || false;
    this.secretCount = secretCount;

    if (this.secret) {
      if (this.secretCount == 0) {
        alert('비밀사진은 최대 3번만 조회할 수 있습니다.');
        this.props.onClose();
      }
      console.log(`this.secret is ${this.secret}`);
      this.timer = setTimeout(() => {
        this.setState({
          show: false
        });

        this.props.onClose();
      }, 800)
    }
  }

  componentWillUnmount() {
    if (this.secret) {
      this.props.onSecretClose(this.id, this.imageUrl, this.secretCount);
    }
    window.removeEventListener("blur", this.onBlur)
    if (this.timer) {
      clearTimeout(this.timer);
    }

    history.goBack();
    this.backListener();
  }

  _isAuthImage(image) {
    if (!image) {
      return false;
    }

    let isAuth = image.lastIndexOf('A.jpg') == image.length - 5;
    console.log(`is Auth is ${isAuth}`)
    return isAuth;
  }

  render() {
    const {classes} = this.props;
    if(!this.state.show) {
      return (<div></div>)
    }

    return (
      <div className={classes.paper}>
        <img style={{width:"100%"}} src={this.state.imageUrl} />
        {this._isAuthImage(this.state.imageUrl) ?
          <div style={{position:"absolute", right:"10px", bottom:"50px", width:'100px', height:'100px'}} className="ball" ></div>
          :
          <></>
        }

      </div>
    )
  }
}

export default withStyles(styles)(PictureView);
