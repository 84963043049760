import React from 'react';
import caller from "../call/caller";
import {withStyles} from "@material-ui/core/styles";
import history from "../history";
import Icon from "@material-ui/core/Icon";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import FilterGenderView from "../modal/FilterGender";
import Modal from "@material-ui/core/Modal";
import PasswordChange from "../modal/PasswordChange";
import {Button} from "@material-ui/core";
import {Auth} from "aws-amplify";

const styles = (theme) => ({
  outer: {
    marginTop: '10px',
    width: "100%",
  },
  inner: {
    maxWidth: "800px",
    width: "95%",
    display: "table",
    margin: "0 auto",
  },
  container: {
    width: "100%",
    display: "inline-block",
    textAlign: "left"
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    backgroundColor: 'white',
    marginTop: '10px',
    height: '40px',
    display: 'flex',
    alignItems: 'center'
  },
  itemIcon: {margin: '13px', marginLeft: '5px'},
  popup: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "95%",
    maxWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
});

class MoreManageAccount extends React.Component {
  state = {
    id: "",
    point: 0,
    user: {},
    alertMessageOpen: false,
    passwordModal: false
  };
  onConfirm = null;
  alertMessage = null;

  constructor(prop) {
    super(prop);
  }

  async componentDidMount() {
    const me = await this.getMyData();
    const {user} = me;
    const {id, point} = me.account;
    const {
      disposition,
      grade,
      isExposeGrade,
      level,
      nickName,
      old,
      profile,
      profileImage,
      profileThumbnailUrl,
      sex,
      userSn
    } = user;

    this.setState({
      id: id,
      point: point,
      user: user
    })
    console.log(me);
  }

  async getMyData() {
    const response = await caller.post("/users/readProfile");
    if (response.data.successYn) {
      let result = response.data.result;
      return result;
    }
    console.log(response.data.statusCode);
    return null;
  }

  async logout() {
    this.clearData();
    this.goToSignIn();
  }

  clearData() {
    caller.defaults.headers.common['Authorization'] = null;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("tokenExpireTime");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem('myData');
  }

  goToSignIn() {
    window.location = "/";
  }

  confirmMessageOepn = async (message, onConfirm) => {
    this.onConfirm = onConfirm;
    this.alertMessage = message
    return new Promise(resolve => {
      this.setState({
        alertMessageOpen: true
      })
    })
  }
  alertMessageOpen = (message) => {
    this.onConfirm = null;
    this.alertMessage = message
    this.setState({
      alertMessageOpen: true
    })
  };

  alertMessageClose = () => {
    this.onConfirm = null;
    this.alertMessage = null;
    this.setState({
      alertMessageOpen: false
    })
  }

  render() {
    const {classes} = this.props;
    const {user, id, point} = this.state;
    console.log(this.state);
    return (
      <React.Fragment>
        <div className={classes.outer}>
          <div className={classes.inner}>
            <div className={classes.container}>
              <div className={classes.buttonContainer}>
                <div className={classes.item} onClick={() => {
                  this.setState({
                    passwordModal: true
                  })
                }}>
                  <Icon className={classes.itemIcon}> vpn_key</Icon>
                  <Link style={{color: 'black'}}>
                    <Typography variant="subtitle1">
                      비밀번호 변경
                    </Typography>
                  </Link>
                </div>

                <div className={classes.item} onClick={() => {
                  history.push({
                    pathname: "/session/forgot_pw"
                  })
                }}>
                  <Icon className={classes.itemIcon}>rotate_left</Icon>
                  <Link style={{color: 'black'}}>
                    <Typography variant="subtitle1">
                      비밀번호 초기화
                    </Typography>
                  </Link>
                </div>

                <div className={classes.item} onClick={() => {
                  this.confirmMessageOepn('정말 로그아웃 하시겠습니까?', () => {
                    this.logout()
                  })
                }}>
                  <Icon className={classes.itemIcon}> exit_to_app</Icon>
                  <Link style={{color: 'black'}}>
                    <Typography variant="subtitle1">
                      로그아웃
                    </Typography>
                  </Link>
                </div>


                <div className={classes.item} onClick={() => {
                  this.confirmMessageOepn('탈퇴 하시겠습니까?\n모든 개인정보는 즉시 삭제되고 복구할 수 없습니다.', async () => {
                    const token = (await Auth.currentSession())
                      .getAccessToken()
                      .getJwtToken();
                    const {data} = await caller.post('users/deactiveUser', {
                      cognitoToken: token,
                    });
                    if (data.successYn) {
                      await this.logout()
                    } else {
                      if (data.statusCode === '') {
                      }
                      alert(
                        '알 수 없는 에러가 발생하였습니다. 잠시 후 다시 시도하여 주세요!',
                      );
                    }
                  })
                }}>
                  <Icon className={classes.itemIcon}> delete_forever</Icon>
                  <Link style={{color: 'black'}}>
                    <Typography variant="subtitle1">
                      회원탈퇴
                    </Typography>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.passwordModal}
          onClose={() => {
            this.setState({
              passwordModal: false
            })
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div>
            <PasswordChange
              onCancel={() => {
                this.setState({
                  passwordModal: false
                })
              }}
              onConfirm={async () => {
                this.setState({
                  passwordModal: false
                })
              }}/>
          </div>
        </Modal>

        <Modal
          open={this.state.alertMessageOpen}
          onClose={this.alertMessageClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div className={classes.popup}>
            <span>
              {this.alertMessage}
            </span>
            {this.onConfirm ?
              <>
                <div style={{textAlign: "right"}}>
                  <Button
                    onClick={() => {
                      this.setState({
                        alertMessageOpen: false
                      })
                    }}
                    color={'secondary'}>취소</Button>
                  <Button
                    onClick={() => {
                      this.onConfirm()
                    }}
                    color={"primary"}>확인</Button>
                </div>
              </>
              :
              <div></div>
            }
          </div>
        </Modal>
      </React.Fragment>
    )
  }
};

export default withStyles(styles)(MoreManageAccount);
