import React from 'react';
import {isAndroid, isChrome, isIOS, isMobile, isSafari, isSamsungBrowser} from "react-device-detect";
import {withStyles} from "@material-ui/core/styles";

const LocationPermissionGuide = ({classes}) => {
  if (isMobile) {
    if (isIOS) {
      if (isChrome) {
        return (
          <div className={classes.guide}>
            <div>위치를 알 수 없습니다.</div>
            <div>브라우저 설정에서 위치정보 사용을 승인 후 다시 시도해주세요.</div>
            <br/>
            <div>* 홈 -> 기기 설정 -> 개인 정보 보호 -> 위치 서비스 -> '위치 서비스 허용' 클릭 -> Chrome -> '앱을 사용하는 동안' 클릭</div>
            <br/>
            <div>* 크롬 -> 설정 -> 개인정보 보호 -> 인터넷 사용 기록 삭제 -> 인터넷 사용 기록 삭제 클릭</div>
          </div>
        )
      }
      if (isSafari) {
        return (
          <div className={classes.guide}>
            <div>위치를 알 수 없습니다.</div>
            <div>브라우저 설정에서 위치정보 사용을 승인 후 다시 시도해주세요.</div>
            <br/>
            <div>* 홈 -> 기기 설정 -> 개인 정보 보호 -> 위치 서비스 -> '위치 서비스 허용' 클릭 -> Safari 웹 사이트 -> '앱을 사용하는 동안' 클릭</div>
            <br/>
            <div>* 홈 -> 기기 설정 -> Safari -> 웹 사이트 설정 -> 위치 -> 허용</div>
          </div>
        )
      }
    } else if (isAndroid) {
      if (isChrome) {
        return (
          <div className={classes.guide}>
            <div>위치를 알 수 없습니다.</div>
            <div>브라우저 설정에서 위치정보 사용을 승인 후 다시 시도해주세요.</div>
            <br/>
            <div>* 기기설정 -> 위치 -> '사용' 클릭 -> Chrome -> 권한 -> 위치 -> '앱 사용 중에만 허용' 클릭</div>
            <br/>
            <div>* 설정 -> 사이트 설정 -> 위치 -> 차단 -> "스윗톡.com" 허용</div>
          </div>
        )
      }
      if (isSamsungBrowser) {
        return (
          <div className={classes.guide}>
            <div>위치를 알 수 없습니다.</div>
            <div>브라우저 설정에서 위치정보 사용을 승인 후 다시 시도해주세요.</div>
            <br/>
            <div>* 기기설정 -> 위치 -> '사용' 클릭 -> 삼성 인터넷 -> 권한 -> 위치 -> '앱 사용 중에만 허용' 클릭</div>
            <br/>
            <div>* 설정 -> 사이트 및 다운로드 -> 웹사이트 데이터 관리 -> 모두 삭제</div>
          </div>
        )
      }
    } else {
      return (
        <div className={classes.guide}>
          <div>위치를 알 수 없습니다.</div>
          <div>브라우저 설정에서 위치정보 사용을 승인 후 다시 시도해주세요.</div>
        </div>
      )
    }
  } else {
    if (isChrome) {
      return (
        <div className={classes.guide}>
          <div>위치를 알 수 없습니다.</div>
          <div>브라우저 설정에서 위치정보 사용을 승인 후 다시 시도해주세요.</div>
          <div>chrome://settings/content/location 를 주소창에 붙여넣어 주세요.</div>
        </div>
      )
    } else {
      return (
        <div className={classes.guide}>
          <div>위치를 알 수 없습니다.</div>
          <div>브라우저 설정에서 위치정보 사용을 승인 후 다시 시도해주세요.</div>
        </div>
      )
    }
  }
}
const styles = (theme) => ({
  guide : {
    margin: '20px',
    textAlign: 'left'
  }
});

export default withStyles(styles)(LocationPermissionGuide)
