/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:747191f4-6a29-486c-b057-8821d76fda17",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_bfCWs8ve2",
    "aws_user_pools_web_client_id": "5717r5apprpu246qapsjjhpaed",
    "oauth": {},
    "aws_user_files_s3_bucket": "sweetchats3",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;
