import React from "react";
import Webcam from "react-webcam";
import {withStyles} from "@material-ui/core/styles";
import {uploadBase64, uploadImage} from "../util/uploadImage";
import myData from "../datastore/Mydata";
import Icon from "@material-ui/core/Icon";

const videoConstraints = {
  width: 720,
  height: 1280,
};
const styles = (theme) => ({
  outer: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "100%",
    maxWidth: "800px",
    height: '100%',
    maxHeight: '100%',
    display:'flex',
    flexDirection:'column',
  },
  cam: {flex: 1, backgroundColor:'black', display:'flex', justifyContent:'center', alignItems:'center'},
  capture: {
    position: "absolute",
    bottom: "100px",
    left: "50%",
    transform: "translate(-50%)",
  },
  facingMode: {
    position: "absolute",
    top: "50px",
    right: "10px",
  }

})
const Camera = ({classes, onUpload, onClose}) => {
  const webcamRef = React.useRef(null);
  const [captured, setCaptured] = React.useState(null);
  const [facingMode, setFacingMode] = React.useState(true);

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setCaptured(imageSrc);
    },
    [webcamRef]
  );

  const recapture = () => {
    setCaptured(null);
  }

  const upload = async() => {
    if (captured == null) {
      return;
    }

    const user = myData.getMyData();
    const url = await uploadBase64(captured, user.userSn, user.sex, 'message', 'A');

    onUpload(url);
    onClose();
  }

  return (
    <div className={classes.outer}>
      <div className={classes.cam}>
        { captured ? (
          <img src={captured} />
        ) : (
          <Webcam
            style={{width: '100%'}}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              ...videoConstraints,
              facingMode: facingMode ? "environment" : "user"
            }}
          />
        )}
      </div>
      { captured ? (
          <div className={classes.capture}>
            <button onClick={upload}>전송</button>
            <button onClick={recapture}>다시 찍기</button>
          </div>
      ) : (
        <button className={classes.capture} onClick={capture}>사진 찍기</button>
      )}
      { captured ? (
        <></>
      ) : (
        <Icon style={{color:'white'}} fontSize={"large"} className={classes.facingMode} onClick={() => {setFacingMode(!facingMode)}}> flip_camera_android_icon</Icon>
      )}

    </div>
  );
};

export default withStyles(styles)(Camera)
